import { ModalComponent } from '@/components';

export default {
  components: {
    ModalComponent,
  },
  data() {
    return {
      isModalVisible: false,
      initialDataForBookingForm: null,
      bookingUuidToEdit: null,
    };
  },
  methods: {
    closeModal() {
      this.bookingUuidToEdit = null;
      this.initialDataForBookingForm = null;
      this.isModalVisible = false;
    },
  },
};
