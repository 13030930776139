<script>
import get from 'lodash/get';
import { mapGetters, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { DELAY } from '@emobg/web-utils';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import {
  attributesToRetrieve,
  facets,
  mainFields,
  restrictSearchableAttributes,
} from './const/vehicleContentCells';
import { exportColumns } from './const/vehicleExportColumns';
import VehicleFormComponent from './components/VehicleFormComponent';

export default {
  name: 'VehiclesView',
  components: {
    MuiAlgoliaList,
    PageView,
    VehicleFormComponent,
  },
  data() {
    return {
      isModalVisible: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorName: state => get(state, 'operators.active.name'),
      operatorTimezone: state => get(state, 'operators.active.timezone'),
      operatorId: state => get(state, 'operators.active.id'),
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'areNonConnectedVehiclesAllowed',
      'getOperatorFilter',
      'isActiveOperatorChild',
    ]),
  },
  created() {
    this.VEHICLES_INDEX = ALGOLIA_INDEXES.vehicles;
    this.VEHICLES_REF = 'vehicles';
    this.attributesToRetrieve = attributesToRetrieve;
    this.restrictSearchableAttributes = restrictSearchableAttributes;
    this.mainFields = mainFields.bind(this)();
    this.facets = facets.bind(this);
    this.exportColumns = exportColumns({
      operatorName: this.operatorName,
      operatorTimezone: this.operatorTimezone,
    });
  },
  methods: {
    onFormSuccess() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs[this.VEHICLES_REF], DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>

<template>
  <PageView
    class="VehiclesView"
    data-test-id="vehicles-view"
  >
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Vehicles
      </h1>
      <ui-button
        data-test-id="create_vehicle-button"
        @clickbutton="isModalVisible = true"
      >
        Create new vehicle
      </ui-button>
    </div>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        :ref="VEHICLES_REF"
        :export-columns="exportColumns"
        :facets="facets()"
        :filters="getOperatorFilter({ index: VEHICLES_INDEX })"
        :index="VEHICLES_INDEX"
        :query-parameters="{ attributesToRetrieve, restrictSearchableAttributes }"
        :table-config="mainFields"
        data-test-id="list"
        is-export-enabled
      />
    </div>
    <VehicleFormComponent
      v-if="isModalVisible"
      :callback="onFormSuccess"
      @closeModal="isModalVisible = false"
    />
  </PageView>
</template>
