import get from 'lodash/get';
import { TIME_ZONE, sentenceCase } from '@emobg/web-utils';

export { contentCells } from './contentCells';

export const facets = [
  {
    type: 'rangeDateTimePicker',
    props: {
      title: 'Date',
      attributeName: 'reporting_timestamp',
      timezone: get(this, 'operator.timezone', TIME_ZONE.default),
      labels: {
        from: 'From',
        to: 'To',
        removeButton: 'Clear dates',
      },

    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Operator',
      attributeName: 'vehicle.cs_operator_name',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'City',
      attributeName: 'vehicle.city',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
    },
  },
];
