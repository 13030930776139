import { createPopper } from '@popperjs/core';
import { DELAY, LOG_TYPE, logger } from '@emobg/web-utils';
export default {
  data() {
    return {
      _popperInstance: null,
      _popperReference: null,
      _popperElement: null,
      _isPopperElementActive: false,
      _isPopperElementHovered: false,
    };
  },
  methods: {
    _createPopper(reference, popper, options) {
      try {
        this._destroyPopperElement();
        this._popperElement = popper;
        this._popperReference = reference;
        this._isPopperElementActive = true;
        this._isPopperElementHovered = false;
        this._popperInstance = createPopper(reference, popper, options);
        this.$nextTick(() => { this._addListeners(); });
      } catch (error) {
        logger.message('error in popperMixin > createPopper');
        logger.message(error, LOG_TYPE.error);
      }
      return this._popperInstance;
    },
    _addListeners() {
      this._popperReference.addEventListener('mouseleave', () => {
        this._isPopperElementActive = false;
        setTimeout(() => {
          if (!this._isPopperElementActive) {
            this._setPopperElementVisibility(false);
          }
        }, DELAY.medium);
      });
      this._popperReference.addEventListener('mouseenter', () => {
        this._isPopperElementActive = true;
        this._setPopperElementVisibility(true);
      });

      this._popperElement.addEventListener('mouseleave', () => {
        this._setPopperElementVisibility(false);
      });
      this._popperElement.addEventListener('mouseenter', () => {
        this._isPopperElementHovered = true;
        this._isPopperElementActive = true;
      });
    },
    _destroyPopperElement() {
      if (this._popperElement) {
        this._popperElement.remove();
        this._popperElement = null;
      }
    },
    _setPopperElementVisibility(isTruthy) {
      if (!this._popperElement) {
        return;
      }
      this._popperInstance.forceUpdate();

      if (isTruthy) {
        this._popperElement.classList.remove('invisible');
        return;
      }
      this._popperElement.classList.add('invisible');
    },
  },
};
