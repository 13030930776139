<script>
export default {
  name: 'CarsharingView',
};
</script>

<template>
  <div class="CarsharingView d-flex flex-column flex-fill">
    <RouterView />
  </div>
</template>
