<script>
import { mapGetters, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  contentCells,
  facets,
} from './config';

export default {
  name: 'DamagesView',
  components: { MuiAlgoliaList, PageView },

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operator: state => state.operators.active,
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    this.DAMAGES_INDEX = ALGOLIA_INDEXES.vehicleDamages;
    this.mainFields = contentCells.bind(this)();
    this.facets = facets;
    this.exportColumns = this.mainFields;
  },
};
</script>

<template>
  <PageView
    class="DamagesView"
    data-test-id="damages-view"
  >
    <h1 class="mb-3">
      Damages
    </h1>

    <MuiAlgoliaList
      :export-columns="exportColumns"
      :facets="facets"
      :filters="getOperatorFilter({ attribute: 'vehicle.cs_operator_fk' })"
      :index="DAMAGES_INDEX"
      :table-config="mainFields"
      data-test-id="list"
      is-export-enabled
    />
  </PageView>
</template>

