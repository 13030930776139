<script>
import assign from 'lodash/assign';
import debounce from 'lodash/debounce';
import delay from 'lodash/delay';
import capitalize from 'lodash/capitalize';
import each from 'lodash/each';
import find from 'lodash/find';
import first from 'lodash/first';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import lowerCase from 'lodash/lowerCase';
import map from 'lodash/map';
import set from 'lodash/set';
import size from 'lodash/size';
import values from 'lodash/values';
import upperFirst from 'lodash/upperFirst';
import Vue from 'vue';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { MuiCollapse } from '@emobg/motion-ui';
import {
  MuiCard,
  MuiInputText,
  MuiSelect,
} from '@emobg/motion-ui/v1';
import {
  DATE_FORMAT,
  DELAY,
  FALLBACK_MESSAGE,
  LOG_TYPE,
  logger,
  normalizeText,
  reformatDateTime,
  sentenceCase,
  TIME_ZONE,
} from '@emobg/web-utils';
import moment from 'moment-timezone';
import { popperMixin } from '@/mixins';
import {
  DayPilot,
  EmptyStateComponent,
  FullscreenButton,
  PageView,
} from '@/components';
import { DATE } from '@/components/DayPilot/constants';
import FullscreenMixin from '@/components/FullscreenButton/FullscreenMixin';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { BLOCK_TYPES, BOOKING_TYPES } from '../const/bookingTypes';
import { ENDED_USAGE_STATUS } from './const';
import newPlanningMixin from './mixins/newPlanningModal';
import {
  BookingSetup,
  BookingSummaryPopup,
  DetailModal,
  EventComponent,
  LocationSummaryPopup,
  MixedUseLegend,
  VehicleSummaryPopup,
} from './components';
import {
  bookingPopperOptions,
  dayPilotConfig,
  dayPilotSeparators,
  dayPilotViewTypes,
  onEventFilter,
  onRowFilter,
  popperOptions,
  resourceLocationTransformer,
  resourceVehicleTransformer,
  timezoneCorrector,
} from './config';
import { parseVehicleLocations } from './store/PlanningModule';
import { CARSHARING_PERMISSIONS } from '../const/permissions';

export default {
  name: 'PlanningView',
  components: {
    BookingSetup,
    DayPilot,
    DetailModal,
    EmptyStateComponent,
    FullscreenButton,
    MixedUseLegend,
    MuiCard,
    MuiCollapse,
    MuiInputText,
    MuiSelect,
    PageView,
  },
  mixins: [newPlanningMixin, FullscreenMixin, popperMixin],
  data() {
    return {
      fullscreen: false,
      isCallbackLoading: false,
      events: [],
      isBookingTypesDisabled: true,
      isFiltersDisabled: true,
      isOperatorsDisabled: true,
      city: undefined,
      cityUuid: null,
      date: moment().startOf('day'),
      searchText: '',
      filters: {
        bookingTypes: [],
        categories: [],
        locations: [],
        users: [],
        operators: [],
      },
      citiesOptions: [],
      bookingTypesOptions: [],
      categoriesOptions: [],
      locationsOptions: [],
      operatorsOptions: [],
      usersOptions: [],
      currentView: 0,
      isMoreFiltersSelected: true,
      vehicleLocationsTuples: [],
      modalBookingInfo: null,
      isDetailModalVisible: false,
      bookingPopupComponent: null,
      bookingEventsPopupTimeout: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
      operatorUuid: state => state.operators.active.uuid,
      userPermissions: state => get(state, 'user.data.permissions', []),
    }),
    ...mapState(DOMAINS_MODEL.carsharing.planning.list, {
      vehicleLocations: state => get(state, 'vehicleLocations.data.locations'),
      isLocationsLoaded: state => get(state, 'vehicleLocations.STATUS.LOADED'),
      bookingsByLocation: state => get(state, 'bookings.data.locations'),
      isBookingsLoaded: state => get(state, 'bookings.STATUS.LOADED'),
      vehicleBlocksByLocation: state => get(state, 'vehicleBlocks.data.locations'),
      bookingBlocksByLocation: state => get(state, 'bookingBlocks.data.locations'),
      dedicatedHoursByLocation: state => get(state, 'dedicatedHours.data.locations'),
      isVehiclesBlocksLoaded: state => get(state, 'vehicleBlocks.STATUS.LOADED'),
      isBookingsBlocksLoaded: state => get(state, 'bookingBlocks.STATUS.LOADED'),
    }),
    ...mapState(DOMAINS_MODEL.carsharing.cities, {
      cities: state => state.data,
      isCitiesLoaded: state => state.STATUS.LOADED,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    emptyMessages() {
      let message;

      if (this.hasNoCities) {
        message = 'No cities available for this operator';
      }

      if (this.city && this.hasNoLocations) {
        message = `No locations active in ${this.city.name}`;
      }

      if (this.city && !this.hasNoLocations && this.hasNoBookings) {
        message = this.hasNoBookingsFeedback;
      }
      return message;
    },
    canViewVehicleLink() {
      return this.userPermissions.includes(CARSHARING_PERMISSIONS.viewCarsharingVehicles);
    },
    isPlanningDataLoading() {
      const dataLoading = this.isLocationsLoaded
        && this.isBookingsLoaded
        && this.isVehiclesBlocksLoaded
        && this.isBookingsBlocksLoaded;
      return !(dataLoading) && !this.hasNoCities;
    },
    moreFiltersGroup() {
      return [
        {
          disabled: this.isOperatorsDisabled || this.isFiltersDisabled,
          label: upperFirst(this.$t('Common.Business.operators')),
          model: 'operators',
          objectValue: 'uuid',
          objectLabel: 'name',
          multipleSelectedLabel: this.getSelectedText('Common.Business.operators_number'),
          options: this.operatorsOptions,
          placeholder: upperFirst(this.$t('Common.Business.operators')),
        },
        {
          disabled: this.isBookingTypesDisabled || this.isFiltersDisabled,
          label: upperFirst(this.$t('Common.Business.categories')),
          model: 'categories',
          objectValue: 'uuid',
          objectLabel: 'name',
          multipleSelectedLabel: this.getSelectedText('Common.Business.categories_number'),
          options: this.categoriesOptions,
          placeholder: upperFirst(this.$t('Common.Business.categories')),
        },
        {
          disabled: this.isFiltersDisabled,
          label: upperFirst(this.$t('Common.Business.locations')),
          model: 'locations',
          objectValue: 'uuid',
          objectLabel: 'name',
          multipleSelectedLabel: this.getSelectedText('Common.Business.locations_number'),
          options: this.locationsOptions,
          placeholder: upperFirst(this.$t('Common.Business.locations')),
        },
        {
          disabled: this.isBookingTypesDisabled || this.isFiltersDisabled,
          label: upperFirst(this.$t('Common.Business.users')),
          model: 'users',
          objectValue: 'uuid',
          objectLabel: 'name',
          multipleSelectedLabel: this.getSelectedText('Common.Business.users_number'),
          options: this.usersOptions,
          placeholder: upperFirst(this.$t('Common.Business.users')),
        },
        {
          disabled: this.isBookingTypesDisabled || this.isFiltersDisabled,
          label: upperFirst(this.$t('Common.Business.booking_types_ua')),
          model: 'bookingTypes',
          objectLabel: 'name',
          objectValue: 'id',
          options: this.bookingTypesOptions,
          multipleSelectedLabel: this.getSelectedText('Common.Business.types'),
          placeholder: upperFirst(this.$t('Common.Business.booking_types_ua')),
        },
      ];
    },
    daypilotDate() {
      return moment(this.date);
    },
    hasNoBookingsFeedback() {
      const days = get(this, `viewTypes.${this.currentView}.config.days`, 1);
      return `${get(this, 'city.name')} has no bookings from ${moment(this.date).format(DATE_FORMAT.date)} to ${moment(this.date).add(days, 'days').format(DATE_FORMAT.date)}`;
    },
    hasNoBookings() {
      return this.isBookingsLoaded && !size(this.bookingsByLocation);
    },
    hasNoLocations() {
      return this.isLocationsLoaded && !size(this.vehicleLocations);
    },
    hasNoCities() {
      return this.isCitiesLoaded && !size(this.cities);
    },
    filtersCounter() {
      const arrays = values(this.filters);
      const flattened = flatten(arrays);
      return size(flattened);
    },
    isDaypilotShown() {
      return size(this.vehicleLocations) || this.isPlanningDataLoading || !this.emptyMessages;
    },
  },
  watch: {
    operatorTimezone() {
      const dayPilot = get(this, '$refs.daypilot');

      if (dayPilot) {
        this.$refs.daypilot.dp.update({
          resources: [],
          events: [],
          separators: dayPilotSeparators(this.operatorTimezone),
        });
      }
    },
    vehicleLocationsTuples(vehicleLocationsTuples) {
      this.getPlanningBookingsAndBlocks(vehicleLocationsTuples);
    },
    operatorUuid: {
      handler(newValue, oldValue) {
        if (oldValue) {
          this.resetCityData();
        }

        this.resetData();
        this.clearFilters();
        this.setCitiesOptions();
        this.setOperatorsOptions();
      },
      immediate: true,
    },
    bookingsByLocation: {
      handler(bookingsByLocation) {
        if (size(bookingsByLocation)) {
          this.generateEvents();
        } else {
          get(this, '$refs.daypilot.dp.update', () => {})({ events: this.events });
          this.usersOptions = [];
          this.bookingTypesOptions = [];
        }
        this.clearNotApplicableFilters();
        this.isFiltersDisabled = false;
      },
      immediate: true,
    },
    vehicleBlocksByLocation: {
      handler(vehicleBlocks) {
        if (size(vehicleBlocks)) {
          this.generateBlocks(vehicleBlocks);
        } else {
          get(this, '$refs.daypilot.dp.update', () => {})({ events: this.events });
        }
      },
      immediate: true,
    },
    dedicatedHoursByLocation: {
      handler(dedicatedHours) {
        if (size(dedicatedHours)) {
          this.generateBlocks(dedicatedHours);
        } else {
          invoke(this, '$refs.daypilot.dp.update', { events: this.events });
        }
      },
      immediate: true,
    },
    bookingBlocksByLocation: {
      handler(bookingBlocks) {
        if (size(bookingBlocks)) {
          this.generateBlocks(bookingBlocks);
        } else {
          get(this, '$refs.daypilot.dp.update', () => {})({ events: this.events });
        }
      },
      immediate: true,
    },
    vehicleLocations: {
      handler(vehicleLocations) {
        this.isFiltersDisabled = true;
        const {
          categoriesOptions,
          locationsOptions,
          resources,
          vehicleLocationsTuples,
        } = parseVehicleLocations({
          vehicleLocations,
          resourceLocationTransformer,
          resourceVehicleTransformer,
          canViewVehicleLink: this.canViewVehicleLink,
        });
        this.categoriesOptions = categoriesOptions;
        this.locationsOptions = locationsOptions;
        this.vehicleLocationsTuples = vehicleLocationsTuples;
        get(this, '$refs.daypilot.dp.update', () => {})({ resources });
      },
      immediate: true,
    },
    isBookingsLoaded(isTrue) {
      if (isTrue) {
        this.isFiltersDisabled = false;
      }
    },
    filters: {
      handler(filters) {
        this.triggerResourcesFilter();
        const isEventFilter = size(filters.bookingTypes) || size(filters.users);
        if (isEventFilter) {
          this.triggerEventsFilter();
        }
      },
      deep: true,
    },
    searchText() {
      this.triggerResourcesFilter();
    },
    city() {
      this.getPlanningResources();
    },
    date(oldDate, newDate) {
      if (this.city && !moment(oldDate).isSame(moment(newDate))) {
        this.getPlanningResources();
      }
    },
    cities(value) {
      if (isArray(value)) {
        this.setCitiesOptions();
      }
    },
  },
  created() {
    this.dayPilotConfig = dayPilotConfig(this.operatorTimezone, this.onBeforeCellRender);
    this.DATE_FORMAT = DATE_FORMAT;
    this.viewTypes = dayPilotViewTypes;
  },
  methods: {
    capitalize,
    find,
    map,
    size,
    upperFirst,
    ...mapActions(DOMAINS_MODEL.carsharing.planning.list, [
      'getPlanningVehicleLocations',
      'postPlanningBookings',
      'postVehicleBlocks',
      'postBookingBlocks',
      'postDedicatedHours',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.planning.list, {
      setPlanningStatus: 'setStatus',
    }),
    ...mapMutations(DOMAINS_MODEL.carsharing.planning.list, [
      'resetData',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.cities, [
      'resetCityData',
    ]),
    normalizeText,
    closeModal() {
      this.isModalVisible = false;
      this.initialDataForBookingForm = null;
      this.bookingUuidToEdit = null;
    },
    getMatchingRangeByCell(cell) {
      const dayPilotFind = get(this, '$refs.daypilot.dp.rows.find');
      if (dayPilotFind) {
        const resource = dayPilotFind(cell.resource);
        const { customRanges } = resource.data;
        return find(customRanges, range => {
          const overlapsStart = moment(cell.start.value, DATE.detail).isSameOrAfter(moment(range.start, DATE.detail));
          const overlapsEnd = moment(cell.end.value, DATE.detail).isSameOrBefore(moment(range.end, DATE.detail));
          return overlapsStart && overlapsEnd;
        });
      }

      return null;
    },
    onBeforeCellRender({ cell }) {
      const range = this.getMatchingRangeByCell(cell);
      if (range) {
        set(cell, 'cssClass', range.cssClass);
      }
    },
    cellBubble({ Bubble }) {
      return new Bubble({
        animated: false,
        zIndex: 1000,
        onLoad: param => {
          const { source } = param;
          const range = this.getMatchingRangeByCell(source);

          if (range) {
            assign(param, {
              html: `<div class="PlanningView__cellBubble">${sentenceCase(range.type)}</div>`,
            });
          }
        },
      });
    },
    dateForRequest(date) {
      const momentTimezoned = moment.tz(date, this.operatorTimezone);
      return momentTimezoned.utc().format(DATE_FORMAT.filter);
    },
    applyPopupClasses(element) {
      this.$nextTick(() => {
        each(['p-3', 'emobg-border-1', 'emobg-border-color-ground-lighter', 'emobg-border-radius-default', 'shadow-m'],
          name => element.classList.add(name));
      });
    },
    showLocationSummaryPopup(locationReference) {
      const uuidNode = locationReference.querySelector('[data-location-uuid]');
      const { locationUuid } = uuidNode.dataset;
      const LocationView = Vue.extend(LocationSummaryPopup);
      const locationElement = new LocationView({
        propsData: { locationUuid },
        parent: this,
      }).$mount().$el;
      document.body.appendChild(locationElement);
      this.applyPopupClasses(locationElement);

      this._createPopper(locationReference, locationElement, popperOptions);
    },
    showVehicleSummaryPopup(vehicleReference) {
      const uuidNode = vehicleReference.querySelector('[data-vehicle-uuid]');
      const { vehicleUuid } = uuidNode.dataset;
      const VehicleView = Vue.extend(VehicleSummaryPopup);
      const vehicleElement = new VehicleView({
        propsData: { vehicleUuid },
        parent: this,
      }).$mount().$el;
      document.body.appendChild(vehicleElement);
      this.applyPopupClasses(vehicleElement);

      this._createPopper(vehicleReference, vehicleElement, popperOptions);
    },
    resourcePop(event) {
      const isVehicle = event.target.closest('.PlanningView__resource');
      if (isVehicle) { // vehicle first, as it's child of location as well
        event.stopPropagation();
        if (this._popperReference !== isVehicle) {
          this.showVehicleSummaryPopup(isVehicle);
        }
        return;
      }

      const isLocation = event.target.closest('.PlanningView__parent-resource');
      if (isLocation) {
        event.stopPropagation();
        if (this._popperReference !== isLocation) {
          this.showLocationSummaryPopup(isLocation);
        }
      }
    },
    onAfterUpdate() {
      if (this.isDaypilotShown) {
        const rowScroller = document.querySelector('.scheduler_default_rowheader_scroll');
        rowScroller.addEventListener('mouseover', this.resourcePop);
      }
    },
    async getPlanningResources() {
      if (get(this, 'city.uuid')) {
        this.isFiltersDisabled = true;
        const startMoment = moment(this.date);
        const startDate = reformatDateTime(this.date, DATE_FORMAT.filter, this.operatorTimezone);
        const days = get(this, `viewTypes.${this.currentView}.config.days`, 1);
        const endDate = reformatDateTime(startMoment.add(days, 'days'), DATE_FORMAT.filter, this.operatorTimezone);

        await this.getPlanningVehicleLocations({
          start_date: this.dateForRequest(startDate),
          end_date: this.dateForRequest(endDate),
          cs_operator_uuid: this.operatorUuid,
          city_uuid: get(this, 'city.uuid'),
          include_child_operators: true,
        });
      }
    },
    getPlanningBookingsAndBlocks(vehicleLocations) {
      if (!vehicleLocations.length) {
        this.setPlanningStatus({ scope: 'bookings', value: 'LOADED' });
        this.setPlanningStatus({ scope: 'bookingBlocks', value: 'LOADED' });
        this.setPlanningStatus({ scope: 'vehicleBlocks', value: 'LOADED' });
        this.isFiltersDisabled = false;
        return;
      }

      const startMoment = moment(this.date);
      const days = get(this, `viewTypes.${this.currentView}.config.days`, 1);
      const endDate = reformatDateTime(startMoment.add(days, 'days'), DATE_FORMAT.filter, this.operatorTimezone);

      const request = {
        vehicle_locations: vehicleLocations,
        start_date: this.dateForRequest(reformatDateTime(this.date, DATE_FORMAT.filter, this.operatorTimezone)),
        end_date: this.dateForRequest(endDate),
        cs_operator_uuid: this.operatorUuid,
      };

      this.events = [];
      this.postVehicleBlocks({ request });
      this.postDedicatedHours({ request });
      this.postBookingBlocks({ request });
      this.postPlanningBookings({ request });
    },
    generateEvents() {
      this.isBookingTypesDisabled = true;
      const usersMap = new Map();
      const bookingTypesMap = new Map();
      each(this.vehicleLocationsTuples, vehicleLocation => {
        const { location_uuid: locationUuid, vehicle_uuid: vehicleUuid } = vehicleLocation;
        const bookings = get(this.bookingsByLocation, `${locationUuid}.vehicles.${vehicleUuid}.bookings`);

        each(bookings, (booking, bookingUuid) => {
          const user = {
            id: booking.user_id,
            name: `${booking.user_name} - ${booking.user_email || FALLBACK_MESSAGE.notAvailable}`,
            uuid: booking.user_uuid,
          };
          if (!usersMap.has(user.id)) {
            usersMap.set(user.id, user);
          }
          const type = booking.is_unavailability ? 'unavailability' : booking.type;
          if (!bookingTypesMap.has(type)) {
            bookingTypesMap.set(type,
              {
                id: type,
                name: upperFirst(lowerCase(type)),
              });
          }
          const bookingTransformed = this.eventBookingTransformer(
            { ...booking, type },
            bookingUuid,
            locationUuid,
            vehicleUuid,
          );
          this.events.push(bookingTransformed);
        });
      });
      this.usersOptions = [...usersMap.values()];
      this.bookingTypesOptions = [...bookingTypesMap.values()];
      this.isBookingTypesDisabled = false;
      this.isFiltersDisabled = false;
      get(this, '$refs.daypilot.dp.update', () => {})({ events: this.events });
    },
    generateBlocks(blocksByLocation) {
      const blockTypes = {
        [BLOCK_TYPES.unavailability]: 'unavailability',
        [BLOCK_TYPES.dedicatedHours]: 'dedicated-hours',
      };

      each(this.vehicleLocationsTuples, vehicleLocation => {
        const { location_uuid: locationUuid, vehicle_uuid: vehicleUuid } = vehicleLocation;
        const blocks = get(blocksByLocation, `${locationUuid}.vehicles.${vehicleUuid}.blocks`);
        each(blocks, block => {
          const blockTransformed = this.eventBlockTransformer(
            block,
            locationUuid,
            vehicleUuid,
          );

          if (block.type && blockTypes[block.type]) {
            const daypilotFind = get(this, '$refs.daypilot.dp.rows.find', () => ({}));
            const resource = daypilotFind(blockTransformed.resource);
            resource.data.customRanges.push({
              start: get(blockTransformed, 'start'),
              end: get(blockTransformed, 'end'),
              cssClass: `PlanningView__cell--${block.type}`,
              type: get(blockTransformed, 'block.type', ''),
            });
          } else {
            this.events.push(blockTransformed);
          }
        });
      });
      const resources = get(this, '$refs.daypilot.dp.resources');
      get(this, '$refs.daypilot.dp.update', () => {})({ events: this.events, resources });
    },
    eventBookingTransformer(booking, bookingUuid, locationUuid, vehicleUuid) {
      const Component = Vue.extend(EventComponent);
      const isOneWayReturnedLocation = booking.is_one_way
        && booking.location_destination_uuid === locationUuid
        && booking.location_origin_uuid !== booking.location_destination_uuid;

      let eventClass = `PlanningView__event PlanningView__event-${booking.type} `;
      eventClass = eventClass.concat(booking.has_alerts ? 'PlanningView__event--alerted' : '');
      eventClass = eventClass.concat(isOneWayReturnedLocation ? 'PlanningView__event--transparent' : '');

      return {
        id: booking.id,
        resource: `${locationUuid}__${vehicleUuid}`,
        booking,
        bookingUuid,
        vehicleUuid,
        isActionsBlocked: isOneWayReturnedLocation,
        html: new Component({
          propsData: {
            booking,
            viewPortDate: this.date,
          },
        }).$mount().$el.outerHTML,
        cssClass: eventClass,
        start: timezoneCorrector(booking.start_date, this.operatorTimezone),
        end: timezoneCorrector(booking.end_date, this.operatorTimezone),
      };
    },
    eventBlockTransformer(block, locationUuid, vehicleUuid) {
      return {
        resource: `${locationUuid}__${vehicleUuid}`,
        block,
        isActionsBlocked: true,
        html: '',
        cssClass: 'PlanningView__block',
        start: timezoneCorrector(block.start_date, this.operatorTimezone),
        end: timezoneCorrector(block.end_date, this.operatorTimezone),
      };
    },
    onTimeRangeSelecting(element) {
      const now = reformatDateTime(moment.utc().subtract(15, 'minutes').format(DATE_FORMAT.filter), DATE_FORMAT.filter, this.operatorTimezone);
      if (moment(element.start, DATE.detail).isBefore(now)) {
        const message = this.$t('Common.Errors.no_element_addition_before_time', {
          element: this.$t('Common.Business.booking'),
          minutes: 15,
        });
        set(element, 'allowed', false);
        set(element, 'left.enabled', true);
        set(element, 'left.html', `<p class="PlanningView__not-allowed px-2 emobg-color-danger emobg-font-x-small">${message}</p>`);
      }
    },
    onTimeRangeSelected(element) {
      const daypilotFind = get(this, '$refs.daypilot.dp.rows.find', () => ({}));
      const resource = daypilotFind(element.resource);
      this.$refs.daypilot.dp.clearSelection();
      if (!resource) {
        return;
      }

      this.initialDataForBookingForm = {
        start: moment(element.start, DATE.detail).format(DATE_FORMAT.filter),
        end: moment(element.end, DATE.detail).format(DATE_FORMAT.filter),
        bookingType: BOOKING_TYPES.carsharing,
        vehicleUuid: get(resource, 'data.vehicleUuid'),
        locationUuid: get(resource, 'data.locationUuid'),
        cityUuid: get(this, 'city.uuid'),
        operatorUuid: get(resource, 'data.vehicle.cs_operator_uuid'),
      };

      this.isModalVisible = true;
    },
    onEventClicked(element) {
      const isActionsBlocked = get(element, 'e.data.isActionsBlocked');
      if (isActionsBlocked) {
        return;
      }

      const isFinishedBooking = ENDED_USAGE_STATUS.includes(get(element, 'e.data.booking.vehicle_status'));
      const bookingId = get(element, 'e.data.booking.id');
      const isOneWay = get(element, 'e.data.booking.is_one_way');
      if (isFinishedBooking) {
        this.modalBookingInfo = {
          bookingId,
          isOneWay,
        };
        this.isDetailModalVisible = true;
        return;
      }
      this.bookingUuidToEdit = get(element, 'e.data.bookingUuid');
      this.initialDataForBookingForm = {
        userUuid: get(element, 'e.data.booking.user_uuid'),
        start: timezoneCorrector(get(element, 'e.data.booking.start_date'), this.operatorTimezone, DATE_FORMAT.filter, DATE_FORMAT.filter),
        end: timezoneCorrector(get(element, 'e.data.booking.end_date'), this.operatorTimezone, DATE_FORMAT.filter, DATE_FORMAT.filter),
        bookingType: get(element, 'e.data.booking.is_unavailability') ? 'unavailability' : get(element, 'e.data.booking.type'),
        vehicleUuid: get(element, 'e.data.vehicleUuid'),
        locationUuid: get(element, 'e.data.booking.location_origin_uuid'),
        returnLocationUuid: get(element, 'e.data.booking.location_destination_uuid'),
        cityUuid: get(this, 'city.uuid'),
      };
      this.isModalVisible = true;
    },
    triggerResourcesFilter: debounce(function triggerResourcesFilter() {
      const daypilotFilter = get(this, '$refs.daypilot.dp.rows.filter', () => {});
      daypilotFilter(this.filters);
    }, DELAY.short),
    triggerEventsFilter: debounce(function triggerEventsFilter() {
      const daypilotFilter = get(this, '$refs.daypilot.dp.events.filter', () => {});
      daypilotFilter(this.filters);
    }, DELAY.short),
    onRowFilter(element) {
      return onRowFilter({ element, searchText: this.searchText });
    },
    onEventFilter,
    clearFilters() {
      this.filters.bookingTypes = [];
      this.filters.categories = [];
      this.filters.locations = [];
      this.filters.users = [];
      this.filters.operators = [];
    },
    clearNotApplicableFilters() {
      const optionsMap = {
        bookingTypes: this.bookingTypesOptions,
        categories: this.categoriesOptions,
        locations: this.locationsOptions,
        users: this.usersOptions,
        operators: this.operatorsOptions,
      };

      const newFilters = {
        bookingTypes: [],
        categories: [],
        locations: [],
        users: [],
        operators: [],
      };

      each(this.filters, (filtersArray, key) => {
        each(filtersArray, filteredItem => {
          if (find(optionsMap[key], optionItem => isEqual(optionItem, filteredItem))) {
            newFilters[key].push(filteredItem);
          }
        });
        this.filters[key] = newFilters[key];
      });
    },
    async setDayPilotView(index) {
      try {
        this.currentView = isNil(index) ? 0 : index;
        await this.getPlanningResources();
        invoke(this.$refs.daypilot, 'setCurrentViewTypeIndex', index);
      } catch (error) {
        logger.message(error, LOG_TYPE.error);
      }
    },
    getSelectedText(translation) {
      return number => {
        const element = this.$t(translation, { number });

        return this.$t('Common.ActionsFinished.selected_element', { element });
      };
    },
    setCitiesOptions() {
      this.isFiltersDisabled = true;
      this.citiesOptions = this.cities;
      this.$nextTick(() => {
        this.city = first(this.cities);
        this.cityUuid = get(this, 'city.uuid', null);
      });
      this.isFiltersDisabled = false;
    },
    async setOperatorsOptions() {
      this.isOperatorsDisabled = true;
      const { hits } = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.csOperators, {
        filters: this.getOperatorFilter({ attribute: 'id' }),
        attributesToHighlight: null,
        attributesToRetrieve: ['uuid', 'name'],
      });
      this.operatorsOptions = hits;
      this.isOperatorsDisabled = false;
    },
    createBookingPopup(event) {
      const bookingId = get(event, 'data.booking.id');
      const isOneWay = get(event, 'data.booking.is_one_way');
      const View = Vue.extend(BookingSummaryPopup);
      this.bookingPopupComponent = new View({
        propsData: {
          bookingId,
          isOneWay,
        },
        parent: this,
      });
      this.bookingPopupComponent.$mount();
      document.body.appendChild(this.bookingPopupComponent.$el);
      this.applyPopupClasses(this.bookingPopupComponent.$el);
      return this.bookingPopupComponent.$el;
    },
    onAfterEventRender({ e: event, div }) {
      // TODO: Refactor popper - https://europcarmobility.atlassian.net/browse/CF-72
      if (!get(event, 'data.isActionsBlocked')) {
        div.addEventListener('mouseenter', () => {
          if (this.bookingEventsPopupTimeout) {
            clearTimeout(this.bookingEventsPopupTimeout);
            this.bookingEventsPopupTimeout = null;
          }
          this.removeBookingPopup();
          const BookingId = event.data.id;
          const reference = div.querySelector(`#id--${BookingId}`);
          const popup = this.createBookingPopup(event);
          this._createPopper(reference, popup, bookingPopperOptions);
        });
        div.addEventListener('mouseleave', () => {
          this.bookingEventsPopupTimeout = delay(() => {
            if (!this._isPopperElementHovered) {
              this.removeBookingPopup();
              this.bookingEventsPopupTimeout = null;
            }
          }, DELAY.short);
        });
      }
    },
    removeBookingPopup() {
      if (this.bookingPopupComponent) {
        this.bookingPopupComponent.$destroy(true);
        this.bookingPopupComponent = null;
      }
      this._destroyPopperElement();
      this._popperReference = null;
    },
    onBookingFormSuccess() {
      this.isCallbackLoading = true;
      // Backend issue with the timing calculating the blocks, we need to add some delay
      setTimeout(() => {
        this.getPlanningResources();
        this.isCallbackLoading = false;
      }, 2500);
    },
  },
};
</script>
<template>
  <PageView class="PlanningView d-flex flex-column flex-fill">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <div class="d-flex align-items-center">
        <h2>
          Planning
        </h2>
        <ui-tooltip
          tooltip="View, edit and create bookings"
          class="ml-1"
        >
          <ui-icon
            :icon="ICONS.infoFull"
            :size="ICONS_SIZES.medium"
            class="emobg-color-ink-light emobg-color-ink-hover"
          />
        </ui-tooltip>
      </div>
      <MixedUseLegend />
    </div>
    <MuiCard
      :class="[
        'd-flex flex-column flex-fill position-relative PlanningView__main-card',
        {
          'PlanningView--fullscreen': fullscreen,
        }
      ]"
    >
      <ui-loader
        v-if="isCallbackLoading || isPlanningDataLoading"
        absolute
        data-test-id="loader"
      />
      <div class="d-flex flex-column flex-fill">
        <div class="d-flex pb-3">
          <div class="flex-grow-1 pr-3">
            <ui-skeleton
              v-if="isPlanningDataLoading"
              class="h-100"
            />
            <MuiInputText
              v-else
              v-model="searchText"
              :disabled="!isLocationsLoaded"
              :icon="ICONS.search"
              data-test-id="planning-search-input"
              class="w-100 PlanningView__search-input"
              name="search"
              icon-to-right
              placeholder="Search vehicle or location"
            />
          </div>
          <ui-button
            class="wmin-initial"
            data-test-id="planning-create_booking-button"
            @clickbutton="isModalVisible = true"
          >
            Create new
          </ui-button>
        </div>
        <div class="d-flex align-items-center pb-2">
          <div class="flex-grow-1 pr-3">
            <ui-button
              :color="GRAYSCALE.inkLight"
              :disabled="isFiltersDisabled"
              :face="FACES.outline"
              data-test-id="planning-more_filters-button"
              @clickbutton="isMoreFiltersSelected = !isMoreFiltersSelected"
            >
              <div class="d-flex align-items-center">
                <ui-icon
                  :icon="ICONS.settingsSlider"
                  :size="SIZES.small"
                  class="mr-2"
                />
                {{ isMoreFiltersSelected ? 'Hide' : 'More' }} filters
                <ui-badge
                  v-if="filtersCounter"
                  solid
                  contrast
                  :color="COLORS.primary"
                  class="ml-1"
                >
                  {{ filtersCounter }}
                </ui-badge>
              </div>
            </ui-button>
            <ui-button
              v-if="isMoreFiltersSelected"
              :disabled="isFiltersDisabled || !filtersCounter"
              :face="FACES.text"
              data-test-id="planning-clear_filters-button"
              class="wmin-initial"
              @clickbutton="clearFilters"
            >
              Clear
            </ui-button>
          </div>

          <MuiSelect
            v-model="cityUuid"
            :options="map(citiesOptions, ({ name, uuid }) => ({ label: name, value: uuid }))"
            :disabled="isFiltersDisabled"
            :placeholder="upperFirst($t('Common.Business.city'))"
            :searchbox="{
              placeholder: '',
              threshold: 1,
              customSearch: (itemList, text) => normalizeText(itemList['label']).includes(normalizeText(text))
            }"
            data-test-id="planning-select_city-selector"
            name="city"
            class="mr-3"
            style="min-width: 160px;"
            @change="city = find(citiesOptions, { uuid: cityUuid })"
          />
          <ui-datetimepicker
            :date.prop="moment(date).format(DATE_FORMAT.dob)"
            :size="SIZES.small"
            :disabled="isFiltersDisabled"
            skiptime
            class="mr-3"
            data-test-id="planning-select_date-datepicker"
            @datechanged="({ detail }) => date = moment(detail)"
          />
          <section class="mr-3">
            <ui-button-segments
              :value="currentView"
              :options.prop="map(viewTypes, ({ label }, index) => ({ label, value: index }))"
              :color="COLORS.primary"
              :default-color="GRAYSCALE.inkLight"
              :disabled="isFiltersDisabled"
              class="Ui-ButtonSegments--minw-initial"
              data-test-id="planning_days-segmented_button"
              @clickbuttonsegment="event => setDayPilotView(event.detail)"
            />
          </section>
          <FullscreenButton
            :fullscreen="fullscreen"
            class="flex-shrink-0"
            data-test-id="planning-fullscreen-button"
            @click="fullscreen = !fullscreen"
          />
        </div>
        <MuiCollapse v-model="isMoreFiltersSelected">
          <section
            class="d-flex flex-wrap align-items-center
              mb-3 p-2 emobg-background-color-ground-lightest
              emobg-border-1 emobg-border-color-ground"
          >
            <MuiSelect
              v-for="selector in moreFiltersGroup"
              :key="selector.label"
              v-model="filters[selector.model]"
              :disabled="selector.disabled"
              :label="selector.label"
              :options="selector.options"
              :option-value="selector.objectValue"
              :option-label="selector.objectLabel"
              :name="selector.label"
              multiple
              class="m-2 flex-grow-1"
            >
              <template #selected="{ item, size }">
                <span v-if="!size"> {{ $t('Common.Actions.select') }} </span>
                <span v-else-if="size === 1"> {{ find(selector.options, [selector.objectValue, item[0]])[selector.objectLabel] }} </span>
                <span v-else> {{ selector.multipleSelectedLabel(size) }} </span>
              </template>
            </MuiSelect>
          </section>
        </MuiCollapse>
        <DayPilot
          v-if="isDaypilotShown"
          ref="daypilot"
          :config="dayPilotConfig"
          :date="daypilotDate"
          :on-after-event-render="onAfterEventRender"
          :on-event-filter="onEventFilter"
          :on-row-filter="onRowFilter"
          :on-time-range-selected="onTimeRangeSelected"
          :on-time-range-selecting="onTimeRangeSelecting"
          :on-event-clicked="onEventClicked"
          :on-after-update="onAfterUpdate"
          :cell-bubble="cellBubble"
          :views="viewTypes"
          :current-view-index="currentView"
          data-test-id="planning-table"
          class="d-flex flex-column full-height flex-fill"
          script-url="/js/daypilot-all.min.js"
        />
        <EmptyStateComponent
          v-else
          :title="emptyMessages"
          has-background
          has-border
          class="py-2"
        />
      </div>
    </MuiCard>
    <BookingSetup
      v-if="isModalVisible"
      :success="onBookingFormSuccess"
      :initial="initialDataForBookingForm"
      :booking-uuid="bookingUuidToEdit"
      @closeModal="closeModal"
      @modal-closed="closeModal"
    />
    <DetailModal
      :open="isDetailModalVisible"
      :title="capitalize($t('Common.Business.booking'))"
      :booking="modalBookingInfo"
      @close="isDetailModalVisible = false"
    />
  </PageView>
</template>
