<script>
import capitalize from 'lodash/capitalize';
import GenericModalComponent from '@/components/Modal/v1/GenericModal/GenericModalComponent.vue';
import BookingSummaryPopup from './BookingSummaryPopup';

export default {
  components: {
    GenericModalComponent,
    BookingSummaryPopup,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    booking: {
      type: Object,
      default: () => ({}),
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.headerConfig = {
      isClosable: false,
      customColors: {
        headerColor: '#E5576A',
      },
    };
  },
  methods: {
    capitalize,
  },
};
</script>
<template>
  <GenericModalComponent
    v-if="open"
    :title="capitalize($t('Common.ActionsFinished.already_finished', { element: $t('Common.Business.booking') }))"
    :header="headerConfig"
    :size="SIZES.small"
    modal-classes="w-auto"
    data-test-id="detail-modal"
    @close-modal="$emit('close')"
  >
    <template #body>
      <div class="DetailModal mt-n2">
        <p class="emobg-font-default emobg-color-ink-light mb-3 text-capitalize">
          {{ $t('Common.Descriptions.details') }}:
        </p>
        <BookingSummaryPopup
          v-if="booking"
          :booking-id="booking.bookingId"
          :is-one-way="booking.isOneWay"
          data-test-id="booking_summary-popup"
          class="pt-2"
        />
      </div>
    </template>
    <template #footer>
      <ui-button
        data-test-id="close-button"
        @clickbutton="$emit('close')"
      >
        {{ $t('Common.Actions.ok') }}
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
