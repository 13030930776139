var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass:
        "VehicleSummaryPopup emobg-caption-1 emobg-color-ink emobg-background-color-white",
      attrs: { "data-test-id": "vehicle_summary-popup" },
    },
    [
      _c("h2", { staticClass: "emobg-font-large emobg-font-weight-bold" }, [
        _vm._v(" Vehicle "),
      ]),
      _vm.vehicle
        ? [
            _c("img", {
              staticClass: "mx-1",
              attrs: {
                src: _vm.vehicle.vehicle_model_picture,
                alt: _vm.vehicleModelBrand,
                width: "200",
              },
            }),
            _c(
              "section",
              { staticClass: "mb-3 mt-1 emobg-caption-1" },
              [
                _c(
                  "PermissionLink",
                  {
                    staticClass: "mt-1",
                    attrs: {
                      "link-permissions": [
                        _vm.CARSHARING_PERMISSIONS.viewCarsharingVehicles,
                      ],
                      to: {
                        name: _vm.fleet.vehicles.detail.details,
                        params: {
                          vehicle_uuid:
                            _vm.vehicle.uuid || _vm.vehicle.vehicle_uuid,
                        },
                      },
                      target: "_blank",
                      "data-test-id": "link",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.plateNumber) + " ")]
                ),
                _c(
                  "p",
                  { staticClass: "mt-1 d-flex align-items-center" },
                  [
                    _vm.isElectricVehicle
                      ? _c("ui-icon", {
                          staticClass: "electric-vehicle-icon-color",
                          attrs: {
                            icon: _vm.ICONS.electric,
                            "data-test-id": "electric_vehicle-icon",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.vehicleModelBrand) + " "),
                    _c("span", { staticClass: "text-capitalize ml-1" }, [
                      _vm._v(" (" + _vm._s(_vm.vehicle.color) + ")"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "p",
                  { staticClass: "my-2 d-flex align-items-center" },
                  [
                    _vm.isOneWayAllowed
                      ? _c("ui-icon", {
                          staticClass: "mr-1",
                          attrs: {
                            icon: _vm.ICONS.carPickUp,
                            size: _vm.ICONS_SIZES.xSmall,
                          },
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "emobg-color-ink-light mr-2" }, [
                      _vm._v(_vm._s(_vm.vehicle.vehicle_category)),
                    ]),
                    _vm.isElectricVehicle
                      ? _c("BatteryLevel", {
                          attrs: {
                            "battery-level": _vm.get(_vm.vehicle, "fuel_level"),
                            "danger-level": _vm.get(
                              _vm.vehicle,
                              "model.fuel_danger_level"
                            ),
                            "icon-size": _vm.SIZES.large,
                            "text-class": "emobg-font-x-small",
                            "data-test-id": "battery_level",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.isOneWayAllowed
                  ? _c(
                      "p",
                      {
                        staticClass: "emobg-font-x-small emobg-color-ink-light",
                      },
                      [_vm._v(" Category available for one way ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "row d-flex align-items-center pb-2 mb-1 emobg-font-x-small",
              },
              [
                _c("div", { staticClass: "col-6 emobg-font-weight-bold" }, [
                  _vm._v(" Connectivity "),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-6 d-flex align-items-center emobg-font-x-small",
                  },
                  [
                    _c("ConnectivityStatusComponent", {
                      attrs: {
                        status: _vm.connectivityStatus,
                        "data-test-id": "connectivity_status",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._l(_vm.tableEntries, function (value, key) {
              return _c(
                "div",
                { key: key, staticClass: "row pb-2 mb-1 emobg-font-x-small" },
                [
                  _c("div", { staticClass: "col-6 emobg-font-weight-bold" }, [
                    _vm._v(" " + _vm._s(key) + " "),
                  ]),
                  _c("div", { staticClass: "col-6 text-capitalize" }, [
                    _vm._v(" " + _vm._s(value) + " "),
                  ]),
                ]
              )
            }),
          ]
        : _c(
            "div",
            [
              _c("ui-skeleton", {
                staticClass: "my-3",
                attrs: { height: "120" },
              }),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("ui-skeleton", {
                    staticClass: "d-block col-6",
                    attrs: { height: "16", rows: "6" },
                  }),
                  _c("ui-skeleton", {
                    staticClass: "d-block col-6",
                    attrs: { height: "16", rows: "6" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }