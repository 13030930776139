<script>
import get from 'lodash/get';
import { sentenceCase } from '@emobg/web-utils';
import moment from 'moment-timezone';
import { BOOKING_TYPES } from '../../../const/bookingTypes';

export default {
  name: 'EventComponent',
  props: {
    booking: {
      type: Object,
      required: true,
    },
    viewPortDate: {
      type: [String, Object],
      required: true,
    },
  },
  computed: {
    bookingType() {
      return get(this, 'booking.is_unavailability') ? 'Unavailability' : sentenceCase(get(this, 'booking.type'));
    },
    isCarsharing() {
      return [
        BOOKING_TYPES.carsharing,
      ].includes(get(this, 'booking.type'));
    },
    startBeforeViewPort() {
      return moment(this.booking.start_date).isBefore(this.viewPortDate);
    },
  },
};
</script>
<template>
  <div
    :id="`id--${booking.id}`"
    class="EventComponent"
  >
    <div class="d-flex align-items-center">
      <span
        v-if="startBeforeViewPort"
        class="icons-arrow-left emobg-caption-1 pr-2"
      />
      <div class="text-ellipsis">
        <div class="emobg-font-weight-bold">
          #{{ booking.id }} - {{ booking.user_name }}
        </div>
        <div class="emobg-font-weight-bold">
          <span v-if="isCarsharing && !booking.is_unavailability">
            {{ booking.is_one_way ? 'One way' : 'Round trip' }}
          </span>
          ({{ bookingType }}<span v-if="booking.is_automatic"> - Automatic </span>)
        </div>
      </div>
    </div>
  </div>
</template>
