var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("GenericModalComponent", {
        attrs: {
          title: _vm.capitalize(
            _vm.$t("Common.ActionsFinished.already_finished", {
              element: _vm.$t("Common.Business.booking"),
            })
          ),
          header: _vm.headerConfig,
          size: _vm.SIZES.small,
          "modal-classes": "w-auto",
          "data-test-id": "detail-modal",
        },
        on: {
          "close-modal": function ($event) {
            return _vm.$emit("close")
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "DetailModal mt-n2" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "emobg-font-default emobg-color-ink-light mb-3 text-capitalize",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("Common.Descriptions.details")) +
                              ": "
                          ),
                        ]
                      ),
                      _vm.booking
                        ? _c("BookingSummaryPopup", {
                            staticClass: "pt-2",
                            attrs: {
                              "booking-id": _vm.booking.bookingId,
                              "is-one-way": _vm.booking.isOneWay,
                              "data-test-id": "booking_summary-popup",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "ui-button",
                    {
                      attrs: { "data-test-id": "close-button" },
                      on: {
                        clickbutton: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Common.Actions.ok")) + " ")]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          577647135
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }