var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "VehicleFormComponent",
        attrs: { title: "Create vehicle", header: { isClosable: true } },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.carsharing.vehicles,
              scope: _vm.scopes.newVehicle,
              "custom-exceptions": [_vm.carsharingErrors.chassisAlreadyExists],
              action: "create vehicle",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (isValid) => (_vm.isFormValid = isValid) } },
            [
              _c("p", { staticClass: "emobg-font-large pb-3" }, [
                _vm._v(" Vehicle Information "),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("MuiInputText", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n                isRequired: true,\n              }",
                          },
                        ],
                        attrs: {
                          label: "License plate*",
                          name: "licensePlate",
                          placeholder: "Enter plate",
                          "data-test-id": "license_plate-input",
                        },
                        model: {
                          value: _vm.inputs.licensePlate,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "licensePlate", $$v)
                          },
                          expression: "inputs.licensePlate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("MuiInputText", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.CHASSIS_VALIDATION,
                            expression: "CHASSIS_VALIDATION",
                          },
                        ],
                        attrs: {
                          maxlength: "17",
                          label: "Chassis number (VIN)*",
                          name: "chassisNumber",
                          placeholder: "Enter number value",
                          "data-test-id": "chassis_number-input",
                        },
                        on: { input: _vm.transformChassisToUpperCase },
                        model: {
                          value: _vm.inputs.chassisNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "chassisNumber", $$v)
                          },
                          expression: "inputs.chassisNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("MuiAlgoliaSelect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n                isRequired: true,\n              }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          index: _vm.ALGOLIA_INDEXES.vehicleCategories,
                          filters: `cs_operator_uuid:${_vm.inputs.csOperatorUuid} AND active: 1`,
                          title: (result) => result.name,
                          "path-value": "uuid",
                          label: "Vehicle category*",
                          name: "category",
                          placeholder: "Select category",
                          "data-test-id": "category-select",
                        },
                        model: {
                          value: _vm.inputs.vehicleCategoryUuid,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "vehicleCategoryUuid", $$v)
                          },
                          expression: "inputs.vehicleCategoryUuid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("MuiSelect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n                isRequired: true,\n              }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          options: _vm.VEHICLE_SEATS_OPTIONS,
                          label: "Number of seats*",
                          name: "seats",
                          placeholder: "Select a number of seats",
                          "data-test-id": "seats-selector",
                        },
                        model: {
                          value: _vm.inputs.seats,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "seats", $$v)
                          },
                          expression: "inputs.seats",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("MuiInputText", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isPattern:
                                _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                            },
                            expression:
                              "{\n                isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n              }",
                          },
                        ],
                        attrs: {
                          label: "Horse power (hp)",
                          name: "horse_power",
                          placeholder: "Enter number value",
                          "data-test-id": "horse_power-input",
                        },
                        model: {
                          value: _vm.inputs.horsePower,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "horsePower", $$v)
                          },
                          expression: "inputs.horsePower",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("MuiInputText", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isPattern:
                                _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
                            },
                            expression:
                              "{\n                isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n              }",
                          },
                        ],
                        attrs: {
                          label: "Fuel consumption (km/l)",
                          name: "fuel",
                          placeholder: "Enter number value (e.g. 6.3)",
                          "data-test-id": "fuel-input",
                        },
                        on: {
                          blur: (value) =>
                            (_vm.inputs.fuelConsumption = _vm.toNumber(value)),
                        },
                        model: {
                          value: _vm.inputs.fuelConsumption,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "fuelConsumption", $$v)
                          },
                          expression: "inputs.fuelConsumption",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("MuiAlgoliaSelect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n                isRequired: true,\n              }",
                          },
                        ],
                        staticClass: "w-100 text-capitalize",
                        attrs: {
                          index: _vm.ALGOLIA_INDEXES.vehicleModels,
                          title: (model) =>
                            `${model.brand.name} ${model.name} (${model.fuel_type})`,
                          "path-value": "uuid",
                          label: "Model*",
                          name: "model",
                          placeholder: "Select",
                          "data-test-id": "model-select",
                        },
                        model: {
                          value: _vm.inputs.vehicleModelUuid,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "vehicleModelUuid", $$v)
                          },
                          expression: "inputs.vehicleModelUuid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("MuiAlgoliaSelect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n                isRequired: true,\n              }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          index: _vm.ALGOLIA_INDEXES.csOperators,
                          title: (operator) => operator.name,
                          filters: `id:${_vm.activeOperatorId} OR parent_cs_operator_id:${_vm.activeOperatorId}`,
                          "path-value": "uuid",
                          label: "CS Operator*",
                          name: "operator",
                          placeholder: "Select the CS Operator",
                          "data-test-id": "operator-select",
                        },
                        model: {
                          value: _vm.inputs.csOperatorUuid,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "csOperatorUuid", $$v)
                          },
                          expression: "inputs.csOperatorUuid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("MuiSelect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n                isRequired: true,\n              }",
                          },
                        ],
                        staticClass: "w-100 text-capitalize",
                        attrs: {
                          options: _vm.values(_vm.VEHICLE_COLORS),
                          label: "Colour*",
                          placeholder: "Select",
                          name: "color",
                          "data-test-id": "color-select",
                        },
                        model: {
                          value: _vm.inputs.color,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "color", $$v)
                          },
                          expression: "inputs.color",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("MuiSelect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n                isRequired: true,\n              }",
                          },
                        ],
                        staticClass: "w-100 text-capitalize",
                        attrs: {
                          options: _vm.values(_vm.TRANSMISSION_TYPES),
                          label: "Transmission*",
                          placeholder: "Select",
                          name: "transmission",
                          "data-test-id": "transmission-select",
                        },
                        model: {
                          value: _vm.inputs.transmission,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "transmission", $$v)
                          },
                          expression: "inputs.transmission",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("MuiInputText", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isPattern:
                                _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
                            },
                            expression:
                              "{\n                isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n              }",
                          },
                        ],
                        attrs: {
                          label: "CO2 emission (g/km)",
                          name: "co2",
                          placeholder: "Enter number value (e.g. 120.4)",
                          "data-test-id": "co2-input",
                        },
                        on: {
                          blur: (value) =>
                            (_vm.inputs.co2Emission = _vm.toNumber(value)),
                        },
                        model: {
                          value: _vm.inputs.co2Emission,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "co2Emission", $$v)
                          },
                          expression: "inputs.co2Emission",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("p", { staticClass: "emobg-font-large pb-3 pt-2" }, [
                _vm._v(" Status "),
              ]),
              _c("div", { staticClass: "row" }, [
                _vm.allowNonConnectedVehicles
                  ? _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "d-block emobg-font-weight-semibold mb-1",
                            },
                            [_vm._v(" Connectivity ")]
                          ),
                          _c("ui-checkbox", {
                            attrs: {
                              checked: _vm.inputs.isNonConnected,
                              caption: "Non-connected",
                              name: "non_connected",
                              "data-test-id": "non_connected-checkbox",
                            },
                            on: { changevalue: _vm.onChangeNonConnected },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "d-block emobg-font-weight-semibold mb-1",
                      },
                      [_vm._v(" Vehicle activation status ")]
                    ),
                    _c("ui-toggle", {
                      staticClass: "mb-4 py-1 d-block",
                      attrs: {
                        value: _vm.inputs.active,
                        text: _vm.inputs.active ? "Active" : "Inactive",
                        "data-test-id": "active-toggle",
                        name: "activityToggle",
                      },
                      on: {
                        changevalue: ({ detail }) =>
                          (_vm.inputs.active = detail),
                      },
                    }),
                  ],
                  1
                ),
                !_vm.inputs.isNonConnected
                  ? _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "d-block emobg-font-weight-semibold mb-1",
                          },
                          [_vm._v(" Vehicle visibility status ")]
                        ),
                        _c("ui-toggle", {
                          staticClass: "mb-4 py-1 d-block",
                          attrs: {
                            value: _vm.inputs.api,
                            text: _vm.inputs.api ? "Active" : "Inactive",
                            "data-test-id": "api-toggle",
                            name: "activityToggle",
                          },
                          on: {
                            changevalue: ({ detail }) =>
                              (_vm.inputs.api = detail),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              !_vm.inputs.isNonConnected
                ? [
                    _c("p", { staticClass: "emobg-font-large pb-3 pt-2" }, [
                      _vm._v(" Hardware "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "d-block emobg-font-weight-semibold mb-1",
                          },
                          [_vm._v(" Device Type ")]
                        ),
                        _c("ui-radio", {
                          attrs: {
                            value: "inputs.isConnectedCarPlatform",
                            option: true,
                            caption: "Connected Car Platform device",
                            name: "is_connected_car_platform",
                            "data-test-id": "connected_car-radio",
                          },
                          on: {
                            changevalue: () => {
                              _vm.inputs.isConnectedCarPlatform = true
                              _vm.onChangeDeviceType()
                            },
                          },
                        }),
                        _c("br"),
                        _c("ui-radio", {
                          attrs: {
                            value: _vm.inputs.isConnectedCarPlatform,
                            option: false,
                            caption: "iBoxx",
                            name: "iBoxx",
                            "data-test-id": "iboxx-radio",
                          },
                          on: {
                            changevalue: () => {
                              _vm.inputs.isConnectedCarPlatform = false
                              _vm.onChangeDeviceType()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.inputs.isConnectedCarPlatform
                      ? _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c(
                              "MuiAlgoliaSelect",
                              {
                                staticClass: "w-100",
                                attrs: {
                                  filters: `${_vm.getOperatorFilter()} AND vehicle_id: 'null'`,
                                  index: _vm.ALGOLIA_INDEXES.devices,
                                  title: (result) => result.label_number,
                                  "show-clear": !!_vm.inputs.deviceUuid,
                                  "on-clear": () =>
                                    (_vm.inputs.deviceUuid = null),
                                  label: `Device number${
                                    _vm.isIBoxxActive ? "*" : ""
                                  }`,
                                  placeholder: "Select",
                                  name: "device_uuid",
                                  "path-value": "uuid",
                                  "data-test-id": "device-select",
                                },
                                model: {
                                  value: _vm.inputs.deviceUuid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "deviceUuid", $$v)
                                  },
                                  expression: "inputs.deviceUuid",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "emobg-font-weight-semibold",
                                    attrs: { slot: "clear" },
                                    slot: "clear",
                                  },
                                  [_vm._v(" Remove ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("MuiAlgoliaSelect", {
                          staticClass: "w-100",
                          attrs: {
                            filters: `vehicle_id: 'null' AND type: 'primary'`,
                            index: _vm.ALGOLIA_INDEXES.fuelcards,
                            title: (result) =>
                              `${result.company} - ${result.number}`,
                            "path-value": "uuid",
                            label: "Fuel card",
                            placeholder: "Select",
                            name: "fuel_card_uuid",
                            "data-test-id": "fuelcard-select",
                          },
                          model: {
                            value: _vm.inputs.fuelcardUuid,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "fuelcardUuid", $$v)
                            },
                            expression: "inputs.fuelcardUuid",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.allowSecondaryFuelCard
                      ? _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiAlgoliaSelect", {
                              staticClass: "w-100",
                              attrs: {
                                filters: `vehicle_id: 'null' AND type: 'secondary'`,
                                index: _vm.ALGOLIA_INDEXES.fuelcards,
                                title: (result) =>
                                  `${result.company} - ${result.number}`,
                                label: "Secondary fuel card",
                                placeholder: "Select",
                                name: "secondary_fuel_card_uuid",
                                "data-test-id": "secondary_fuelcard-select",
                                "path-value": "uuid",
                              },
                              model: {
                                value: _vm.inputs.secondaryFuelcardUuid,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.inputs,
                                    "secondaryFuelcardUuid",
                                    $$v
                                  )
                                },
                                expression: "inputs.secondaryFuelcardUuid",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c("p", { staticClass: "emobg-font-large pb-3 pt-2" }, [
                _vm._v(" Financial information "),
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("ui-select", {
                      staticClass: "w-100",
                      attrs: {
                        value: _vm.inputs.juridicStatus,
                        placeholder: "Select",
                        label: "Juridic status",
                        name: "juridic_status",
                        "data-test-id": "juridic_status-select",
                      },
                      domProps: {
                        options: _vm.map(_vm.JURIDIC_STATUS, (value) => ({
                          label: _vm.sentenceCase(value),
                          value,
                        })),
                      },
                      on: {
                        selectoption: ({ detail }) =>
                          (_vm.inputs.juridicStatus = detail),
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("ui-datetimepicker", {
                      staticClass: "w-100",
                      attrs: {
                        size: _vm.SIZES.small,
                        label: "First registration date",
                        name: "first_registration_date",
                        "data-test-id": "registration_date-input",
                        skiptime: "",
                      },
                      domProps: { date: _vm.inputs.firstRegistrationDate },
                      on: {
                        datechanged: ({ detail }) =>
                          (_vm.inputs.firstRegistrationDate = detail),
                      },
                    }),
                    _vm.inputs.firstRegistrationDate
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "text-right emobg-font-weight-semibold cursor-pointer mx-1 emobg-font-x-small emobg-color-primary emobg-color-primary-dark-hover",
                            attrs: { "data-tes-id": "clear_date-action" },
                            on: {
                              click: function ($event) {
                                _vm.inputs.firstRegistrationDate = null
                              },
                            },
                          },
                          [_vm._v(" Clear date ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c("CancelButton", {
              attrs: { "data-test-id": "cancel-button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("closeModal")
                },
              },
            }),
            _c(
              "ui-button",
              {
                staticClass: "wmin-initial",
                attrs: {
                  disabled: !_vm.isValidatedForm,
                  loading: _vm.newVehicleStatus.LOADING,
                  "data-test-id": "create-button",
                },
                on: { clickbutton: _vm.createVehicle },
              },
              [_vm._v(" Create vehicle ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }