<script>
import first from 'lodash/first';
import get from 'lodash/get';
import { logger } from '@emobg/web-utils';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import fleet from '@domains/Fleet/router/FleetRouterMap';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { PermissionLink } from '@/components';

import { formatNil } from '../../utils/formatters';

export default {
  name: 'LocationSummaryPopup',
  components: {
    PermissionLink,
  },
  props: {
    locationUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      location: null,
    };
  },
  created() {
    this.getLocation();
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.fleet = fleet;
  },
  methods: {
    formatNil,
    get,
    async getLocation() {
      try {
        const { hits } = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.locations, {
          filters: `uuid:${this.locationUuid}`,
        });
        this.location = first(hits);
      } catch (error) {
        logger.message(`No hits for location uuid: '${this.locationUuid}'`);
      }
    },
  },
};
</script>
<template>
  <section
    class="LocationSummaryPopup emobg-caption-1 emobg-color-ink emobg-background-color-white overflow-y-auto"
    data-test-id="location_summary-popup"
  >
    <h2 class="emobg-font-large emobg-font-weight-bold">
      Location
    </h2>
    <div
      v-if="get(location, 'one_way_allowed')"
      class="pt-1 pb-2"
    >
      <ui-icon
        :icon="ICONS.carPickUp"
        :size="ICONS_SIZES.xSmall"
        class="mr-1"
      />
      <span>Available for one way</span>
    </div>
    <template v-if="location">
      <section class="mb-3">
        <PermissionLink
          :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingLocations]"
          :to="{
            name: fleet.locations.detail.index,
            params: {
              locationUuid: location.uuid,
            },
          }"
          target="_blank"
          class="mt-1"
          data-test-id="link"
        >
          {{ formatNil(location.name) }}
        </PermissionLink>
        <p class="emobg-color-ink-light text-capitalize mt-1">
          {{ formatNil(location.type) }}
        </p>
      </section>
      <div class="emobg-caption">
        <p class="emobg-font-weight-bold mt-1">
          Description
        </p>
        <p
          class="ml-3 mt-1 emobg-color-ink-light emobg-font-x-small"
          v-html="formatNil(location.description).replace(/\n/g, '<br/>')"
        />
      </div>
    </template>
    <div v-else>
      <div
        v-for="item of 6"
        :key="item"
        class="row my-3"
      >
        <ui-skeleton
          height="16"
          rows="2"
          class="col-6"
        />
      </div>
    </div>
  </section>
</template>
