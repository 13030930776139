var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "DamagesView", attrs: { "data-test-id": "damages-view" } },
    [
      _c("h1", { staticClass: "mb-3" }, [_vm._v(" Damages ")]),
      _c("MuiAlgoliaList", {
        attrs: {
          "export-columns": _vm.exportColumns,
          facets: _vm.facets,
          filters: _vm.getOperatorFilter({
            attribute: "vehicle.cs_operator_fk",
          }),
          index: _vm.DAMAGES_INDEX,
          "table-config": _vm.mainFields,
          "data-test-id": "list",
          "is-export-enabled": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }