import join from 'lodash/join';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  isFalsyString,
  reformatDateTime,
  sentenceCase,
  toBoolean,
} from '@emobg/web-utils';

export function exportColumns({
  operatorName,
  operatorTimezone,
}) {
  return [
    {
      attributeName: 'license_plate',
      title: 'Plate',
    },
    {
      title: 'Model',
      transformResult: result => `${result.vehicle_brand} - ${result.vehicle_model}`,
    },
    {
      attributeName: 'vehicle_category',
      title: 'Category',
    },
    {
      attributeName: 'model.vehicle_type',
      title: 'Type',
      transformValue: sentenceCase,
    },
    {
      attributeName: 'city',
      title: 'City',
    },
    {
      attributeName: 'location',
      title: 'Location',
      transformValue: location => location.replace(/"/g, "'"),
    },
    {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
    },
    {
      attributeName: 'vehicle_model_picture',
      title: 'Picture',
    },
    {
      attributeName: 'color',
      title: 'Color',
    },
    {
      attributeName: 'vehicle_fuel_type',
      title: 'Fuel type',
      transformValue: sentenceCase,
    },
    {
      title: 'Premium vehicle',
      transformResult: result => (result.is_premium ? 'Yes' : 'No'),
    },
    {
      attributeName: 'device.qnr',
      title: 'Device QNR',
      transformValue: value => value || FALLBACK_MESSAGE.dash,
    },
    {
      title: 'Mileage',
      transformResult: result => `${result.mileage || FALLBACK_MESSAGE.dash} ${result.mileage_unit || FALLBACK_MESSAGE.dash}`,
    },
    {
      title: 'Battery level',
      attributeName: 'battery_level',
      transformValue: value => value || FALLBACK_MESSAGE.dash,
    },
    {
      title: 'Battery level last update',
      attributeName: 'battery_level_timestamp',
      transformValue: value => (value
        ? reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone)
        : FALLBACK_MESSAGE.dash),
    },
    {
      attributeName: 'chassis_number',
      title: 'Chassis number',
    },
    {
      title: 'Alert reasons',
      attributeName: 'alerts',
      transformValue: alerts => join(alerts, ', '),
    },
    {
      attributeName: 'seats',
      title: 'Seats',
    },
    {
      attributeName: 'transmission',
      title: 'Transmission',
      transformValue: sentenceCase,
    },
    {
      title: 'Visible',
      transformResult: result => (result.api ? 'Yes' : 'No'),
    },
    {
      attributeName: 'dedicated_cs_operator',
      title: 'Operator',
      transformValue: value => (isFalsyString(value) ? operatorName : value),
    },
    {
      attributeName: 'is_connected_car_platform',
      title: 'Device type',
      transformValue: value => (toBoolean(value) ? 'Connected Car Platform device' : 'iBoxx'),
    },
  ];
}
