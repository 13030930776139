var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass:
        "LocationSummaryPopup emobg-caption-1 emobg-color-ink emobg-background-color-white overflow-y-auto",
      attrs: { "data-test-id": "location_summary-popup" },
    },
    [
      _c("h2", { staticClass: "emobg-font-large emobg-font-weight-bold" }, [
        _vm._v(" Location "),
      ]),
      _vm.get(_vm.location, "one_way_allowed")
        ? _c(
            "div",
            { staticClass: "pt-1 pb-2" },
            [
              _c("ui-icon", {
                staticClass: "mr-1",
                attrs: {
                  icon: _vm.ICONS.carPickUp,
                  size: _vm.ICONS_SIZES.xSmall,
                },
              }),
              _c("span", [_vm._v("Available for one way")]),
            ],
            1
          )
        : _vm._e(),
      _vm.location
        ? [
            _c(
              "section",
              { staticClass: "mb-3" },
              [
                _c(
                  "PermissionLink",
                  {
                    staticClass: "mt-1",
                    attrs: {
                      "link-permissions": [
                        _vm.CARSHARING_PERMISSIONS.viewCarsharingLocations,
                      ],
                      to: {
                        name: _vm.fleet.locations.detail.index,
                        params: {
                          locationUuid: _vm.location.uuid,
                        },
                      },
                      target: "_blank",
                      "data-test-id": "link",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.formatNil(_vm.location.name)) + " ")]
                ),
                _c(
                  "p",
                  { staticClass: "emobg-color-ink-light text-capitalize mt-1" },
                  [_vm._v(" " + _vm._s(_vm.formatNil(_vm.location.type)) + " ")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "emobg-caption" }, [
              _c("p", { staticClass: "emobg-font-weight-bold mt-1" }, [
                _vm._v(" Description "),
              ]),
              _c("p", {
                staticClass:
                  "ml-3 mt-1 emobg-color-ink-light emobg-font-x-small",
                domProps: {
                  innerHTML: _vm._s(
                    _vm
                      .formatNil(_vm.location.description)
                      .replace(/\n/g, "<br/>")
                  ),
                },
              }),
            ]),
          ]
        : _c(
            "div",
            _vm._l(6, function (item) {
              return _c(
                "div",
                { key: item, staticClass: "row my-3" },
                [
                  _c("ui-skeleton", {
                    staticClass: "col-6",
                    attrs: { height: "16", rows: "2" },
                  }),
                ],
                1
              )
            }),
            0
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }