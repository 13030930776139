var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "EventComponent", attrs: { id: `id--${_vm.booking.id}` } },
    [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm.startBeforeViewPort
          ? _c("span", { staticClass: "icons-arrow-left emobg-caption-1 pr-2" })
          : _vm._e(),
        _c("div", { staticClass: "text-ellipsis" }, [
          _c("div", { staticClass: "emobg-font-weight-bold" }, [
            _vm._v(
              " #" +
                _vm._s(_vm.booking.id) +
                " - " +
                _vm._s(_vm.booking.user_name) +
                " "
            ),
          ]),
          _c("div", { staticClass: "emobg-font-weight-bold" }, [
            _vm.isCarsharing && !_vm.booking.is_unavailability
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.booking.is_one_way ? "One way" : "Round trip"
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm._v(" (" + _vm._s(_vm.bookingType)),
            _vm.booking.is_automatic
              ? _c("span", [_vm._v(" - Automatic ")])
              : _vm._e(),
            _vm._v(") "),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }