<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import toUpper from 'lodash/toUpper';
import values from 'lodash/values';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { DATE_FORMAT, sentenceCase } from '@emobg/web-utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { CancelButton, GenericModalComponent, StoreNotificationComponent } from '@/components';
import { PATTERN_INPUT_VALIDATIONS, toNumberUtil as toNumber } from '@/utils';
import { CHASSIS_VALIDATION } from '../const/validations';
import {
  JURIDIC_STATUS,
  TRANSMISSION_TYPES,
  VEHICLE_COLORS,
} from '../Details/Setup/components/const/editVehicleComponents';
import { CARSHARING_PERMISSIONS } from '../../const/permissions';
import { scopes } from '../store/VehiclesModule';
import { carsharing as carsharingErrors } from '../../const/errorKeys';
import { SEATS_OPTIONS } from '../const/vehicles.const';

export default {
  name: 'VehicleFormComponent',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiInputText,
    MuiSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        licensePlate: null,
        vehicleModelUuid: null,
        chassisNumber: null,
        csOperatorUuid: null,
        vehicleCategoryUuid: null,
        color: null,
        seats: null,
        transmission: null,
        horsePower: null,
        co2Emission: null,
        fuelConsumption: null,
        isNonConnected: false,
        active: false,
        api: false,
        isConnectedCarPlatform: true,
        deviceUuid: null,
        fuelcardUuid: null,
        secondaryFuelcardUuid: null,
        juridicStatus: null,
        firstRegistrationDate: null,
      },
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['getOperatorFilter']),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      permissions: state => get(state, 'user.data.permissions'),
      activeOperatorId: state => state.operators.active.id,
      activeOperatorUuid: state => state.operators.active.uuid,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicles, {
      newVehicle: state => state.newVehicle.data.data,
      newVehicleStatus: state => state.newVehicle.STATUS,
    }),
    allowSecondaryFuelCard() {
      return includes(this.permissions, CARSHARING_PERMISSIONS.allowSecondaryFuelCard);
    },
    allowNonConnectedVehicles() {
      return includes(this.permissions, CARSHARING_PERMISSIONS.allowNonConnectedVehicles);
    },
    isIBoxxActive() {
      return this.inputs.active && !this.inputs.isConnectedCarPlatform && !this.inputs.isNonConnected;
    },
    isValidatedForm() {
      return this.isIBoxxActive ? this.isFormValid && this.inputs.deviceUuid : this.isFormValid;
    },
  },
  created() {
    this.VEHICLE_SEATS_OPTIONS = SEATS_OPTIONS;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.scopes = scopes;
    this.carsharingErrors = carsharingErrors;
    this.VEHICLE_COLORS = VEHICLE_COLORS;
    this.TRANSMISSION_TYPES = TRANSMISSION_TYPES;
    this.JURIDIC_STATUS = JURIDIC_STATUS;
    this.DATE_FORMAT = DATE_FORMAT;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.CHASSIS_VALIDATION = CHASSIS_VALIDATION;
    this.inputs.csOperatorUuid = this.activeOperatorUuid;
    this.resetState({ scopes: [scopes.newVehicle] });
  },
  methods: {
    map,
    values,
    ...mapActions(DOMAINS_MODEL.carsharing.vehicles, [
      'postVehicle',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.vehicles, [
      'resetState',
    ]),
    toNumber,
    sentenceCase,
    onChangeNonConnected() {
      if (this.inputs.isNonConnected) {
        this.inputs.deviceUuid = null;
        this.inputs.api = false;
        this.inputs.fuelcardUuid = null;
        this.inputs.secondaryFuelcardUuid = null;
      }
    },
    onChangeDeviceType() {
      if (this.inputs.isConnectedCarPlatform) {
        this.inputs.deviceUuid = null;
      }
    },
    transformChassisToUpperCase(value) {
      this.inputs.chassisNumber = toUpper(value);
    },
    async createVehicle() {
      const data = cloneDeep(this.inputs);
      data.firstRegistrationDate = data.firstRegistrationDate ? data.firstRegistrationDate.format(DATE_FORMAT.date) : null;
      await this.postVehicle(data);

      if (!this.newVehicleStatus.ERROR) {
        const { licensePlate, uuid } = this.newVehicle;
        const licensePlateNode = `Vehicle with license plate <a href="/fleet/vehicles/${uuid}/details" class="text-decoration-none emobg-font-weight-semibold emobg-color-primary">${licensePlate}</a>`;
        const message = `${licensePlateNode} was successfully <span class="emobg-font-weight-bold">created</span>`;
        this.$emit('closeModal');
        this.$notify({
          message,
          textAction: 'Dismiss',
        });
        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    title="Create vehicle"
    :header="{ isClosable: true }"
    class="VehicleFormComponent"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.carsharing.vehicles"
        :scope="scopes.newVehicle"
        :custom-exceptions="[carsharingErrors.chassisAlreadyExists]"
        action="create vehicle"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="isValid => isFormValid = isValid">
        <p class="emobg-font-large pb-3">
          Vehicle Information
        </p>
        <div class="row">
          <div class="col-6">
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.licensePlate"
                v-validate="{
                  isRequired: true,
                }"
                label="License plate*"
                name="licensePlate"
                placeholder="Enter plate"
                data-test-id="license_plate-input"
              />
            </div>
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.chassisNumber"
                v-validate="CHASSIS_VALIDATION"
                maxlength="17"
                label="Chassis number (VIN)*"
                name="chassisNumber"
                placeholder="Enter number value"
                data-test-id="chassis_number-input"
                @input="transformChassisToUpperCase"
              />
            </div>
            <div class="mb-4">
              <MuiAlgoliaSelect
                v-model="inputs.vehicleCategoryUuid"
                v-validate="{
                  isRequired: true,
                }"
                :index="ALGOLIA_INDEXES.vehicleCategories"
                :filters="`cs_operator_uuid:${inputs.csOperatorUuid} AND active: 1`"
                :title="result => result.name"
                class="w-100"
                path-value="uuid"
                label="Vehicle category*"
                name="category"
                placeholder="Select category"
                data-test-id="category-select"
              />
            </div>
            <div class="mb-4">
              <MuiSelect
                v-model="inputs.seats"
                v-validate="{
                  isRequired: true,
                }"
                :options="VEHICLE_SEATS_OPTIONS"
                class="w-100"
                label="Number of seats*"
                name="seats"
                placeholder="Select a number of seats"
                data-test-id="seats-selector"
              />
            </div>
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.horsePower"
                v-validate="{
                  isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
                }"
                label="Horse power (hp)"
                name="horse_power"
                placeholder="Enter number value"
                data-test-id="horse_power-input"
              />
            </div>
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.fuelConsumption"
                v-validate="{
                  isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
                }"
                label="Fuel consumption (km/l)"
                name="fuel"
                placeholder="Enter number value (e.g. 6.3)"
                data-test-id="fuel-input"
                @blur="value => inputs.fuelConsumption = toNumber(value)"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="mb-4">
              <MuiAlgoliaSelect
                v-model="inputs.vehicleModelUuid"
                v-validate="{
                  isRequired: true,
                }"
                :index="ALGOLIA_INDEXES.vehicleModels"
                :title="model => `${model.brand.name} ${model.name} (${model.fuel_type})`"
                class="w-100 text-capitalize"
                path-value="uuid"
                label="Model*"
                name="model"
                placeholder="Select"
                data-test-id="model-select"
              />
            </div>
            <div class="mb-4">
              <MuiAlgoliaSelect
                v-model="inputs.csOperatorUuid"
                v-validate="{
                  isRequired: true,
                }"
                :index="ALGOLIA_INDEXES.csOperators"
                :title="operator => operator.name"
                :filters="`id:${activeOperatorId} OR parent_cs_operator_id:${activeOperatorId}`"
                class="w-100"
                path-value="uuid"
                label="CS Operator*"
                name="operator"
                placeholder="Select the CS Operator"
                data-test-id="operator-select"
              />
            </div>

            <div class="mb-4">
              <MuiSelect
                v-model="inputs.color"
                v-validate="{
                  isRequired: true,
                }"
                :options="values(VEHICLE_COLORS)"
                class="w-100 text-capitalize"
                label="Colour*"
                placeholder="Select"
                name="color"
                data-test-id="color-select"
              />
            </div>

            <div class="mb-4">
              <MuiSelect
                v-model="inputs.transmission"
                v-validate="{
                  isRequired: true,
                }"
                :options="values(TRANSMISSION_TYPES)"
                class="w-100 text-capitalize"
                label="Transmission*"
                placeholder="Select"
                name="transmission"
                data-test-id="transmission-select"
              />
            </div>

            <div class="mb-4">
              <MuiInputText
                v-model="inputs.co2Emission"
                v-validate="{
                  isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
                }"
                label="CO2 emission (g/km)"
                name="co2"
                placeholder="Enter number value (e.g. 120.4)"
                data-test-id="co2-input"
                @blur="value => inputs.co2Emission = toNumber(value)"
              />
            </div>
          </div>
        </div>

        <p class="emobg-font-large pb-3 pt-2">
          Status
        </p>
        <div class="row">
          <div
            v-if="allowNonConnectedVehicles"
            class="col-12"
          >
            <div class="mb-4">
              <span class="d-block emobg-font-weight-semibold mb-1"> Connectivity </span>
              <ui-checkbox
                :checked="inputs.isNonConnected"
                caption="Non-connected"
                name="non_connected"
                data-test-id="non_connected-checkbox"
                @changevalue="onChangeNonConnected"
              />
            </div>
          </div>
          <div class="col-6">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Vehicle activation status
            </label>
            <ui-toggle
              :value="inputs.active"
              :text="inputs.active ? 'Active' : 'Inactive'"
              data-test-id="active-toggle"
              name="activityToggle"
              class="mb-4 py-1 d-block"
              @changevalue="({ detail }) => inputs.active = detail"
            />
          </div>
          <div
            v-if="!inputs.isNonConnected"
            class="col-6"
          >
            <label class="d-block emobg-font-weight-semibold mb-1">
              Vehicle visibility status
            </label>
            <ui-toggle
              :value="inputs.api"
              :text="inputs.api ? 'Active' : 'Inactive'"
              data-test-id="api-toggle"
              name="activityToggle"
              class="mb-4 py-1 d-block"
              @changevalue="({ detail }) => inputs.api = detail"
            />
          </div>
        </div>

        <template v-if="!inputs.isNonConnected">
          <p class="emobg-font-large pb-3 pt-2">
            Hardware
          </p>
          <div class="mb-4">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Device Type
            </label>
            <ui-radio
              value="inputs.isConnectedCarPlatform"
              :option="true"
              caption="Connected Car Platform device"
              name="is_connected_car_platform"
              data-test-id="connected_car-radio"
              @changevalue="() => {
                inputs.isConnectedCarPlatform = true;
                onChangeDeviceType();
              }"
            />
            <br>
            <ui-radio
              :value="inputs.isConnectedCarPlatform"
              :option="false"
              caption="iBoxx"
              name="iBoxx"
              data-test-id="iboxx-radio"
              @changevalue="() => {
                inputs.isConnectedCarPlatform = false;
                onChangeDeviceType();
              }"
            />
          </div>
          <div
            v-if="!inputs.isConnectedCarPlatform"
            class="mb-4"
          >
            <MuiAlgoliaSelect
              v-model="inputs.deviceUuid"
              :filters="`${getOperatorFilter()} AND vehicle_id: 'null'`"
              :index="ALGOLIA_INDEXES.devices"
              :title="result => result.label_number"
              :show-clear="!!inputs.deviceUuid"
              :on-clear="() => inputs.deviceUuid = null"
              :label="`Device number${isIBoxxActive ? '*': ''}`"
              class="w-100"
              placeholder="Select"
              name="device_uuid"
              path-value="uuid"
              data-test-id="device-select"
            >
              <span
                slot="clear"
                class="emobg-font-weight-semibold"
              >
                Remove
              </span>
            </MuiAlgoliaSelect>
          </div>
          <div class="mb-4">
            <MuiAlgoliaSelect
              v-model="inputs.fuelcardUuid"
              :filters="`vehicle_id: 'null' AND type: 'primary'`"
              :index="ALGOLIA_INDEXES.fuelcards"
              :title="result => `${result.company} - ${result.number}`"
              path-value="uuid"
              label="Fuel card"
              placeholder="Select"
              name="fuel_card_uuid"
              data-test-id="fuelcard-select"
              class="w-100"
            />
          </div>
          <div
            v-if="allowSecondaryFuelCard"
            class="mb-4"
          >
            <MuiAlgoliaSelect
              v-model="inputs.secondaryFuelcardUuid"
              :filters="`vehicle_id: 'null' AND type: 'secondary'`"
              :index="ALGOLIA_INDEXES.fuelcards"
              :title="result => `${result.company} - ${result.number}`"
              class="w-100"
              label="Secondary fuel card"
              placeholder="Select"
              name="secondary_fuel_card_uuid"
              data-test-id="secondary_fuelcard-select"
              path-value="uuid"
            />
          </div>
        </template>

        <p class="emobg-font-large pb-3 pt-2">
          Financial information
        </p>
        <div class="row">
          <div class="col-6">
            <ui-select
              :value="inputs.juridicStatus"
              :options.prop="map(JURIDIC_STATUS, (value) => ({ label: sentenceCase(value), value }))"
              placeholder="Select"
              label="Juridic status"
              name="juridic_status"
              class="w-100"
              data-test-id="juridic_status-select"
              @selectoption="({ detail }) => inputs.juridicStatus = detail"
            />
          </div>
          <div class="col-6">
            <ui-datetimepicker
              :size="SIZES.small"
              :date.prop="inputs.firstRegistrationDate"
              label="First registration date"
              name="first_registration_date"
              data-test-id="registration_date-input"
              class="w-100"
              skiptime
              @datechanged="({ detail }) => inputs.firstRegistrationDate = detail"
            />
            <p
              v-if="inputs.firstRegistrationDate"
              class="text-right emobg-font-weight-semibold cursor-pointer mx-1 emobg-font-x-small emobg-color-primary emobg-color-primary-dark-hover"
              data-tes-id="clear_date-action"
              @click="inputs.firstRegistrationDate = null"
            >
              Clear date
            </p>
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="cancel-button"
          @click="$emit('closeModal')"
        />

        <ui-button
          :disabled="!isValidatedForm"
          :loading="newVehicleStatus.LOADING"
          class="wmin-initial"
          data-test-id="create-button"
          @clickbutton="createVehicle"
        >
          Create vehicle
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
<style lang="scss">
  .VehicleFormComponent {
    .v1-MuiSelect__label-text {
      padding: 0 !important;
    }
  }
</style>
