<script>
export default {
  name: 'MixedUseLegend',
  created() {
    this.legend = [
      {
        text: 'Open hours',
        background: 'emobg-background-color-ground-lightest',
      },
      {
        text: 'Dedicated company hours',
        background: 'emobg-background-color-ground',
      },
    ];
  },
};
</script>
<template>
  <div
    class="d-flex align-items-center MixedUseLegend"
    data-test-id="mixed_used_legend-view"
  >
    <template v-for="(item, index) in legend">
      <div
        :key="`grid-${index}`"
        :data-test-id="`grid-${index}`"
        :class="['d-flex MixedUseLegend__item ml-4 emobg-border-1 emobg-border-color-ink-lighter emobg-border-radius-small', `${item.background}`]"
      >
        <div />
        <div />
        <div />
      </div>
      <span
        :key="`text-${index}`"
        :data-test-id="`text-${index}`"
        class="emobg-font-weight-semibold ml-1"
      >
        {{ item.text }}
      </span>
    </template>
  </div>
</template>
<style lang="scss">
  .MixedUseLegend {
    &__item {
      div {
        width: 10px;
        height: 16px;
      }

      div:nth-child(2) {
        box-sizing: content-box;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
      }
    }
  }
</style>
