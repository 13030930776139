var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-items-center MixedUseLegend",
      attrs: { "data-test-id": "mixed_used_legend-view" },
    },
    [
      _vm._l(_vm.legend, function (item, index) {
        return [
          _c(
            "div",
            {
              key: `grid-${index}`,
              class: [
                "d-flex MixedUseLegend__item ml-4 emobg-border-1 emobg-border-color-ink-lighter emobg-border-radius-small",
                `${item.background}`,
              ],
              attrs: { "data-test-id": `grid-${index}` },
            },
            [_c("div"), _c("div"), _c("div")]
          ),
          _c(
            "span",
            {
              key: `text-${index}`,
              staticClass: "emobg-font-weight-semibold ml-1",
              attrs: { "data-test-id": `text-${index}` },
            },
            [_vm._v(" " + _vm._s(item.text) + " ")]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }