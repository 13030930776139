import get from 'lodash/get';
import join from 'lodash/join';
import toLower from 'lodash/toLower';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { FALLBACK_MESSAGE, sentenceCase, toBoolean } from '@emobg/web-utils';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import fleetRouter from '@domains/Fleet/router/FleetRouterMap';
import { AlertsTemplate } from '@/templates';
import { ConnectivityStatusComponent, PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { CARSHARING_PERMISSIONS } from '../../const/permissions';
import VehicleLink from '../../components/VehicleLink';

export const attributesToRetrieve = [
  'id',
  'uuid',
  'license_plate',
  'vehicle_brand',
  'vehicle_model',
  'vehicle_category',
  'model',
  'city',
  'location',
  'alerts',
  'status',
  'telemetry.connectivity_status',
  'vehicle_model_picture',
  'vehicle_fuel_type',
  'is_premium',
  'device',
  'mileage',
  'mileage_unit',
  'seats',
  'transmission',
  'api',
  'dedicated_cs_operator',
  'dedicated_cs_fk',
  'battery_level',
  'battery_level_timestamp',
  'chassis_number',
  'non_connected',
  'color',
  'is_connected_car_platform',
];

export const restrictSearchableAttributes = [
  'uuid',
  'id',
  'license_plate',
  'vehicle_brand',
  'vehicle_model',
  'vehicle_category',
  'model.brand.uuid',
  'model.uuid',
  'city',
  'location',
  'alerts',
  'status',
  'vehicle_fuel_type',
  'is_premium',
  'device.qnr',
  'mileage',
  'mileage_unit',
  'seats',
  'transmission',
  'api',
  'dedicated_cs_operator',
  'non_connected',
];

export function mainFields() {
  return [
    {
      attributeName: 'license_plate',
      title: 'Plate',
      displayPriority: 1,
      columnRatio: 1,
      minWidth: 90,
      type: RECORD_TYPES.component,
      component: VehicleLink,
      props: result => ({ result }),
    },
    {
      title: 'Model',
      transformResult: result => `${result.vehicle_brand} - ${result.vehicle_model}`,
      displayPriority: 1,
      columnRatio: 2,
      minWidth: 150,
    },
    {
      attributeName: 'vehicle_category',
      title: 'Category',
      displayPriority: 1,
      columnRatio: 1,
      minWidth: 90,
    },
    {
      attributeName: 'model.vehicle_type',
      title: 'Type',
      displayPriority: 1,
      columnRatio: 1,
      minWidth: 120,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'city',
      title: 'City',
      displayPriority: 1,
      columnRatio: 1,
      minWidth: 100,
    },
    {
      attributeName: 'location',
      title: 'Location',
      columnRatio: 3,
      displayPriority: 2,
      minWidth: 170,
      transformValue: location => location.replace(/"/g, "'"),
    },
    {
      title: 'Alerts',
      attributeName: 'alerts',
      columnRatio: 1,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ alerts }) => {
        const numberOfAlerts = get(alerts, 'length');
        return {
          text: numberOfAlerts || '0',
          color: numberOfAlerts ? COLORS.warning : GRAYSCALE.ground,
        };
      },
      minWidth: 120,
      displayPriority: 1,
      isCollapseHidden: true,
    },
    {
      title: 'Status',
      attributeName: 'status',
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: status === 'active' ? COLORS.success : COLORS.danger,
      }),
      columnRatio: 1,
      displayPriority: 2,
      minWidth: 120,
    },
    {
      title: 'Connectivity',
      type: RECORD_TYPES.component,
      displayPriority: 2,
      minWidth: 165,
      component: ConnectivityStatusComponent,
      props: vehicle => ({
        showIcon: false,
        status: get(vehicle, 'telemetry.connectivity_status'),
        isNonConnected: !!get(vehicle, 'non_connected'),
      }),
    },
    {
      attributeName: 'vehicle_model_picture',
      title: 'Picture',
      type: RECORD_TYPES.image,
      columnRatio: 1,
      minWidth: 120,
      displayPriority: 2,
    },
    {
      attributeName: 'vehicle_fuel_type',
      title: 'Fuel type',
      displayPriority: 0,
      minWidth: 70,
      transformValue: sentenceCase,
    },
    {
      title: 'Premium vehicle',
      minWidth: 120,
      transformResult: result => (result.is_premium ? 'Yes' : 'No'),
    },
    {
      attributeName: 'device.qnr',
      title: 'Device QNR',
      displayPriority: 0,
      minWidth: 155,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: vehicle => {
        const deviceQNR = get(vehicle, 'device.qnr', FALLBACK_MESSAGE.dash);
        const deviceLabelNumber = get(vehicle, 'device.label_number');
        const query = (deviceLabelNumber && { query: deviceLabelNumber }) || {};
        const to = deviceLabelNumber && { name: fleetRouter.hardware.devices, query };
        return {
          classLink: 'emobg-link-primary emobg-body-2',
          to,
          linkPermissions: [CARSHARING_PERMISSIONS.viewSettingsFleetHardware],
          text: deviceQNR,
        };
      },
    },
    {
      title: 'Mileage',
      displayPriority: 0,
      minWidth: 85,
      transformResult: result => `${result.mileage || FALLBACK_MESSAGE.dash} ${result.mileage_unit || FALLBACK_MESSAGE.dash}`,
    },
    {
      title: 'Battery level',
      attributeName: 'battery_level',
      displayPriority: 0,
      minWidth: 85,
      transformValue: value => value || FALLBACK_MESSAGE.dash,
    },
    {
      attributeName: 'chassis_number',
      title: 'Chassis number',
      displayPriority: 0,
      minWidth: 170,
    },
    {
      title: 'Alert reasons',
      attributeName: 'alerts',
      type: RECORD_TYPES.component,
      component: AlertsTemplate,
      displayPriority: 0,
      minWidth: 280,
      props: result => ({
        result,
        alerts: result.alerts,
      }),
      columnRatio: 1,
      transformValue: alerts => join(alerts, ', '),
    },
    {
      attributeName: 'seats',
      title: 'Seats',
      displayPriority: 0,
      minWidth: 40,
    },
    {
      attributeName: 'transmission',
      title: 'Transmission',
      transformValue: sentenceCase,
      minWidth: 95,
    },
    {
      title: 'Visible',
      transformResult: result => (result.api ? 'Yes' : 'No'),
      displayPriority: 0,
      minWidth: 50,
    },
    {
      attributeName: 'dedicated_cs_operator',
      displayPriority: 0,
      title: 'Operator',
      minWidth: 70,
      transformValue: value => (toLower(value) === 'null' ? this.operatorName : value),
    },
    {
      attributeName: 'is_connected_car_platform',
      displayPriority: 0,
      title: 'Device type',
      minWidth: 70,
      transformValue: value => (toBoolean(value) ? 'Connected Car Platform device' : 'iBoxx'),
    },
  ];
}

export function facets() {
  const nonConnectedFacet = {
    type: 'refinementList',
    props: {
      title: 'Non-connected vehicle',
      attributeName: 'non_connected',
      transformValue: value => (value === '1' ? 'Yes' : 'No'),
    },
  };

  const facetsList = [
    {
      type: 'refinementList',
      props: {
        title: 'Operator',
        attributeName: 'dedicated_cs_operator',
        transformValue: value => (String(value).toLowerCase() === 'null' ? this.operatorName : value),
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'City',
        attributeName: 'city',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Location',
        attributeName: 'location',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Status',
        attributeName: 'status',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Alerts',
        attributeName: 'alerts',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Connectivity',
        attributeName: 'telemetry.connectivity_status',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Brand',
        attributeName: 'vehicle_brand',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Model',
        attributeName: 'vehicle_model',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Category',
        attributeName: 'vehicle_category',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Type',
        attributeName: 'model.vehicle_type',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Fuel type',
        attributeName: 'vehicle_fuel_type',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Premium vehicle',
        attributeName: 'is_premium',
        transformValue: value => (value === '1' ? 'Yes' : 'No'),
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Transmission',
        attributeName: 'transmission',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Visible',
        attributeName: 'api',
        transformValue: value => (value === '1' ? 'Yes' : 'No'),
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Seats',
        attributeName: 'seats',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Device type',
        attributeName: 'is_connected_car_platform',
        transformValue: value => (toBoolean(value) ? 'Connected Car Platform device' : 'iBoxx'),
      },
    },
  ];

  if (this.areNonConnectedVehiclesAllowed) {
    facetsList.splice(6, 0, nonConnectedFacet);
  }

  return facetsList;
}
