import { KEYCODE } from '@emobg/web-utils';

export default {
  mounted() {
    window.addEventListener('keyup', this.escapeListener);
    window.addEventListener('resize', this.resizeListener);
  },
  destroyed() {
    window.removeEventListener('keyup', this.escapeListener);
    window.removeEventListener('resize', this.resizeListener);
  },
  methods: {
    resizeListener() {
      if (window.matchMedia('(max-width: 767px)').matches) {
        this.fullscreen = false;
      }
    },
    escapeListener(e) {
      if (e.keyCode === KEYCODE.ESCAPE) {
        this.fullscreen = false;
      }
    },
  },
};
