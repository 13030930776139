var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "PlanningView d-flex flex-column flex-fill" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-between mb-2",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("h2", [_vm._v(" Planning ")]),
              _c(
                "ui-tooltip",
                {
                  staticClass: "ml-1",
                  attrs: { tooltip: "View, edit and create bookings" },
                },
                [
                  _c("ui-icon", {
                    staticClass: "emobg-color-ink-light emobg-color-ink-hover",
                    attrs: {
                      icon: _vm.ICONS.infoFull,
                      size: _vm.ICONS_SIZES.medium,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("MixedUseLegend"),
        ],
        1
      ),
      _c(
        "MuiCard",
        {
          class: [
            "d-flex flex-column flex-fill position-relative PlanningView__main-card",
            {
              "PlanningView--fullscreen": _vm.fullscreen,
            },
          ],
        },
        [
          _vm.isCallbackLoading || _vm.isPlanningDataLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex flex-column flex-fill" },
            [
              _c(
                "div",
                { staticClass: "d-flex pb-3" },
                [
                  _c(
                    "div",
                    { staticClass: "flex-grow-1 pr-3" },
                    [
                      _vm.isPlanningDataLoading
                        ? _c("ui-skeleton", { staticClass: "h-100" })
                        : _c("MuiInputText", {
                            staticClass: "w-100 PlanningView__search-input",
                            attrs: {
                              disabled: !_vm.isLocationsLoaded,
                              icon: _vm.ICONS.search,
                              "data-test-id": "planning-search-input",
                              name: "search",
                              "icon-to-right": "",
                              placeholder: "Search vehicle or location",
                            },
                            model: {
                              value: _vm.searchText,
                              callback: function ($$v) {
                                _vm.searchText = $$v
                              },
                              expression: "searchText",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "ui-button",
                    {
                      staticClass: "wmin-initial",
                      attrs: {
                        "data-test-id": "planning-create_booking-button",
                      },
                      on: {
                        clickbutton: function ($event) {
                          _vm.isModalVisible = true
                        },
                      },
                    },
                    [_vm._v(" Create new ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-items-center pb-2" },
                [
                  _c(
                    "div",
                    { staticClass: "flex-grow-1 pr-3" },
                    [
                      _c(
                        "ui-button",
                        {
                          attrs: {
                            color: _vm.GRAYSCALE.inkLight,
                            disabled: _vm.isFiltersDisabled,
                            face: _vm.FACES.outline,
                            "data-test-id": "planning-more_filters-button",
                          },
                          on: {
                            clickbutton: function ($event) {
                              _vm.isMoreFiltersSelected =
                                !_vm.isMoreFiltersSelected
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("ui-icon", {
                                staticClass: "mr-2",
                                attrs: {
                                  icon: _vm.ICONS.settingsSlider,
                                  size: _vm.SIZES.small,
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isMoreFiltersSelected ? "Hide" : "More"
                                  ) +
                                  " filters "
                              ),
                              _vm.filtersCounter
                                ? _c(
                                    "ui-badge",
                                    {
                                      staticClass: "ml-1",
                                      attrs: {
                                        solid: "",
                                        contrast: "",
                                        color: _vm.COLORS.primary,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.filtersCounter) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.isMoreFiltersSelected
                        ? _c(
                            "ui-button",
                            {
                              staticClass: "wmin-initial",
                              attrs: {
                                disabled:
                                  _vm.isFiltersDisabled || !_vm.filtersCounter,
                                face: _vm.FACES.text,
                                "data-test-id": "planning-clear_filters-button",
                              },
                              on: { clickbutton: _vm.clearFilters },
                            },
                            [_vm._v(" Clear ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("MuiSelect", {
                    staticClass: "mr-3",
                    staticStyle: { "min-width": "160px" },
                    attrs: {
                      options: _vm.map(_vm.citiesOptions, ({ name, uuid }) => ({
                        label: name,
                        value: uuid,
                      })),
                      disabled: _vm.isFiltersDisabled,
                      placeholder: _vm.upperFirst(
                        _vm.$t("Common.Business.city")
                      ),
                      searchbox: {
                        placeholder: "",
                        threshold: 1,
                        customSearch: (itemList, text) =>
                          _vm
                            .normalizeText(itemList["label"])
                            .includes(_vm.normalizeText(text)),
                      },
                      "data-test-id": "planning-select_city-selector",
                      name: "city",
                    },
                    on: {
                      change: function ($event) {
                        _vm.city = _vm.find(_vm.citiesOptions, {
                          uuid: _vm.cityUuid,
                        })
                      },
                    },
                    model: {
                      value: _vm.cityUuid,
                      callback: function ($$v) {
                        _vm.cityUuid = $$v
                      },
                      expression: "cityUuid",
                    },
                  }),
                  _c("ui-datetimepicker", {
                    staticClass: "mr-3",
                    attrs: {
                      size: _vm.SIZES.small,
                      disabled: _vm.isFiltersDisabled,
                      skiptime: "",
                      "data-test-id": "planning-select_date-datepicker",
                    },
                    domProps: {
                      date: _vm.moment(_vm.date).format(_vm.DATE_FORMAT.dob),
                    },
                    on: {
                      datechanged: ({ detail }) =>
                        (_vm.date = _vm.moment(detail)),
                    },
                  }),
                  _c(
                    "section",
                    { staticClass: "mr-3" },
                    [
                      _c("ui-button-segments", {
                        staticClass: "Ui-ButtonSegments--minw-initial",
                        attrs: {
                          value: _vm.currentView,
                          color: _vm.COLORS.primary,
                          "default-color": _vm.GRAYSCALE.inkLight,
                          disabled: _vm.isFiltersDisabled,
                          "data-test-id": "planning_days-segmented_button",
                        },
                        domProps: {
                          options: _vm.map(
                            _vm.viewTypes,
                            ({ label }, index) => ({ label, value: index })
                          ),
                        },
                        on: {
                          clickbuttonsegment: (event) =>
                            _vm.setDayPilotView(event.detail),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("FullscreenButton", {
                    staticClass: "flex-shrink-0",
                    attrs: {
                      fullscreen: _vm.fullscreen,
                      "data-test-id": "planning-fullscreen-button",
                    },
                    on: {
                      click: function ($event) {
                        _vm.fullscreen = !_vm.fullscreen
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "MuiCollapse",
                {
                  model: {
                    value: _vm.isMoreFiltersSelected,
                    callback: function ($$v) {
                      _vm.isMoreFiltersSelected = $$v
                    },
                    expression: "isMoreFiltersSelected",
                  },
                },
                [
                  _c(
                    "section",
                    {
                      staticClass:
                        "d-flex flex-wrap align-items-center mb-3 p-2 emobg-background-color-ground-lightest emobg-border-1 emobg-border-color-ground",
                    },
                    _vm._l(_vm.moreFiltersGroup, function (selector) {
                      return _c("MuiSelect", {
                        key: selector.label,
                        staticClass: "m-2 flex-grow-1",
                        attrs: {
                          disabled: selector.disabled,
                          label: selector.label,
                          options: selector.options,
                          "option-value": selector.objectValue,
                          "option-label": selector.objectLabel,
                          name: selector.label,
                          multiple: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selected",
                              fn: function ({ item, size }) {
                                return [
                                  !size
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("Common.Actions.select")
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : size === 1
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.find(selector.options, [
                                                selector.objectValue,
                                                item[0],
                                              ])[selector.objectLabel]
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              selector.multipleSelectedLabel(
                                                size
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.filters[selector.model],
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, selector.model, $$v)
                          },
                          expression: "filters[selector.model]",
                        },
                      })
                    }),
                    1
                  ),
                ]
              ),
              _vm.isDaypilotShown
                ? _c("DayPilot", {
                    ref: "daypilot",
                    staticClass: "d-flex flex-column full-height flex-fill",
                    attrs: {
                      config: _vm.dayPilotConfig,
                      date: _vm.daypilotDate,
                      "on-after-event-render": _vm.onAfterEventRender,
                      "on-event-filter": _vm.onEventFilter,
                      "on-row-filter": _vm.onRowFilter,
                      "on-time-range-selected": _vm.onTimeRangeSelected,
                      "on-time-range-selecting": _vm.onTimeRangeSelecting,
                      "on-event-clicked": _vm.onEventClicked,
                      "on-after-update": _vm.onAfterUpdate,
                      "cell-bubble": _vm.cellBubble,
                      views: _vm.viewTypes,
                      "current-view-index": _vm.currentView,
                      "data-test-id": "planning-table",
                      "script-url": "/js/daypilot-all.min.js",
                    },
                  })
                : _c("EmptyStateComponent", {
                    staticClass: "py-2",
                    attrs: {
                      title: _vm.emptyMessages,
                      "has-background": "",
                      "has-border": "",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isModalVisible
        ? _c("BookingSetup", {
            attrs: {
              success: _vm.onBookingFormSuccess,
              initial: _vm.initialDataForBookingForm,
              "booking-uuid": _vm.bookingUuidToEdit,
            },
            on: { closeModal: _vm.closeModal, "modal-closed": _vm.closeModal },
          })
        : _vm._e(),
      _c("DetailModal", {
        attrs: {
          open: _vm.isDetailModalVisible,
          title: _vm.capitalize(_vm.$t("Common.Business.booking")),
          booking: _vm.modalBookingInfo,
        },
        on: {
          close: function ($event) {
            _vm.isDetailModalVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }